<template>
  <div class="register-item">
    <!-- <div slot="title" class="login-header">复投财经</div> -->
    <template>
      <el-form
        ref="loginForm2"
        :model="reqData2"
        :rules="loginForm2Rules"
        class="login-form"
        label-position="left"
      >
        <el-form-item prop="Mobile" class="login-form-item">
          <el-input
            v-model="reqData2.Mobile"
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item prop="Code" class="login-form-item">
          <el-input
            v-model="reqData2.Code"
            prefix-icon="el-icon-connection"
            placeholder="请输入验证码"
          />
          <el-button type="text" :disabled="smsDisabled" class="btn_sms" @click="new_getSms">{{ smsBtnText }}</el-button>
        </el-form-item>
        <el-form-item prop="Pwd" class="login-form-item">
          <el-input
            v-model="reqData2.Pwd"
            prefix-icon="el-icon-lock"
            show-password
            type="password"
            placeholder="请输入账号密码"
            @keyup.enter.native="newhandleSubmit"
          />
        </el-form-item>
        <p @click.stop="goLogin" class="goLogin">已有账号,马上登录</p>
        <el-form-item label-width="0">
          <div class="login-form-btn" @click.stop="newhandleSubmit">注册</div>
        </el-form-item>
        <p class="accept">我已阅读和接受<span @click="userAgreement">《用户协议》</span></p>
      </el-form>
    </template>
    <div v-if="controlImgCodeDialog" class="codeDialog">
      <img :src="imgcodelocal" alt="">
      <p>
        <span>图形验证码:</span>
        <input type="text" v-model="codeNumber">
      </p>
      <div>
        <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
        <button @click="confirm(codeNumber)">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
import {getGuid} from '../../../../../utils/guid';
import {verifyImgCode} from '../../../../../api/info';
export default {
  data() {
    return {
      activeName: 'first',
      smsTime: 60,
      reqData2: {
        Mobile: '',
        Pwd: '',
        Code: '',
        device: 'pc',
        plats:[{
          id:1,
        }]
      },
      smsDisabled: false,
      smsBtnText: '获取验证码',
      loginForm2Rules: {
        Mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' }
        ],
        Pwd: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        Code: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      // 用户填写的图片验证码结果
      codeNumber:'',
      // 给用户随机生成token
      guid:'',
    }
  },
  computed: {
    dialogObj() {
      return this.$store.state.home.dialogObj
    },
    controlActiveName(){
      return this.$store.state.login.activeName
    }
  },
  watch: {
    'dialogObj.dialogVisible'(newVal) {
      if (!newVal) {
        // this.$refs['loginForm'].resetFields()
        this.$refs['loginForm2'].resetFields()
      }
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    async _getSms() {
      const params = {
        phone: this.reqData2.phone
      }
      const res = await this.$store.dispatch('login/handleSms', params)
      console.log(res)
      setInterval(() => {
        this.smsBtnText = this.smsTime + '秒后重新获取'
        this.smsTime--
        if (this.smsTime < 0) {
          this.smsBtnText = '获取验证码'
          this.smsDisabled = false
          clearInterval()
        }
      }, 1000)
    },
    // 提交注册
    handleSubmit() {
      this.$refs['loginForm2'].validate(async valid => {
        if (valid) {
          await this.$store.dispatch('login/handleRegister', this.reqData2);
          this.$refs['loginForm2'].resetFields();
          this.$store.commit('home/set_dialogobj', { dialogVisible: false });
        }
      })
    },
    // craig--新增注册
    newhandleSubmit() {
      this.$refs['loginForm2'].validate(async valid => {
        if (valid) {
          let data = {
            IsQuickRregister:true,
            Mobile:this.reqData2.Mobile,
            Code:this.reqData2.Code,
            Token:this.guid,
            LoginType:1,
            AuthMode:1,
            ClientId:localStorage.getItem('clientid'),
            Pwd:this.reqData2.Pwd,
            Channel: 5100// this.$route.query.v || '',
          }
          this.$store.commit('home/set_userPwd',this.reqData2.Pwd)
          await this.$store.dispatch('login/newhandleRegister', data);
          this.$refs['loginForm2'].resetFields();
          this.$store.commit('home/set_dialogobj', { dialogVisible: false });
        }
      })
    },
    // craig--新增验证码
    async new_getSms() {
      this.controlImgCodeDialog = true;
      this.guid = getGuid();
      this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
      setInterval(() => {
        this.smsBtnText = this.smsTime + '秒后重新获取'
        this.smsTime--
        if (this.smsTime < 0) {
          this.smsBtnText = '获取验证码'
          this.smsDisabled = false
          clearInterval()
        }
      }, 1000)
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.reqData2.Mobile,
          Token: this.guid,
          ImgCode:Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'success'
            });
            // this.form.code = res.data;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'error',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'error'
        });
      }
    },
    goLogin(){
      this.$store.commit('login/set_activeName', 'second');
    },
    userAgreement(){
      window.open('https://www.rlcproltd.net/uc/userAgreement');
    }
  }
}
</script>

<style lang="less">
.register-item {
  .login-header {
    text-align: center;
    font-size: 22px;
    color: #3498db;
    margin-top: -50px;
    line-height: 40px;
  }
  .login-form {
    margin-top: 30px;
    .login-form-item {
      width: 81%;
      margin: 15px auto;
      padding: 0 10px;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      border: 1px solid #c0c4cc;
      .el-form-item__label {
        text-align: center !important;
        color: #394d62;
        font-size: 16px;
      }
      input {
        flex: 1;
        font-size: 16px;
        color: #394d62;
        outline: none;
        border: none;
      }
    }
    .btn_sms{
      position: absolute;
      right: 0;
    }
    .login-form-btn {
      width: 85%;
      margin: 30px auto 0;
      background-color: #f22118;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 50px;
      font-size: 18px;
      border-radius: 8px;
      cursor: pointer;
      height: 50px;
    }
    .goLogin{
      width: 85%;
      margin: 0 auto 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .accept{
      width: 85%;
      margin: 0 auto;
      span{
        color: #35a2ff;
        cursor: pointer;
      }
    }
  }
  .codeDialog{
    width: 350px;
    height: 250px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 5px;
    z-index: 99;
    // border: 2px solid #2c2c2c;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 1px 1px 10px #a3a2a2;
    p{
      span{
        color: #6d6d6d;
        font-size: 18px;
        font-weight: 600;
      }
      input{
        width: 150px;
        height: 50px;
        background-color: #e2e2e2;
        border: none;
        margin-left: 20px;
        text-indent: 20px;
        color: #2c2c2c;
      }
    }
    div{
      button{
        width: 120px;
        height: 40px;
        border: 1px solid #ee2547;
        background-color: #ffffff;
        color: #ee2547;
        font-size: 18px;
        border-radius: 3px;
        margin: 0 10px;
      }
      button:nth-child(2){
        background-color: #ee2547;
        color: #ffffff;
      }
    }
  }
}
</style>
