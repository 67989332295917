<template>
  <div class="box scroll-item chat-list">
    <div class="item" v-for="(item,i) in bnotes" :key="i">
      <div class="top">
        <!-- <img class="teacherHeaderImg" :src="teacherInfo ? teacherInfo[1].avatar : ''" alt=""> -->
        <p>
          <span>{{item.trealname}}</span>
          <span>{{timestampToTime(item.ut)}}</span>
        </p>
      </div>
      <div class="bottom" v-html="item.info">
      </div>
    </div>
    <div v-if="!bnotes.length" class="nodata">暂无讲义</div>
  </div>
</template>

<script>
export default {
  name:'handouts',
  computed:{
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    bnotes(){
      return this.$store.state.home.bnotes
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
  },
  created(){
    // 初始化课堂笔记
    this.getBnotes();
  },
  mounted(){
  },
  methods:{
    // craig--获取课堂笔记列表
    async getBnotes(){
      const option = {
        // broomid:this.roomInfo.id,
        broomid:this.roomInfo.id,
        // tid:this.teacherInfo[1].id,
        // bsheetid:this.teacherInfo[0].value.id,
        // bsheetid:this.teacherInfo[0].value.id,
        sort:'ut,DESC',
      }
      await this.$store.dispatch('home/getBnotes',option)
    },
    // 时间戳转时间
    timestampToTime(timestamp) {
      let date = new Date(parseInt(timestamp));
      let Year = date.getFullYear();
      let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
      let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Year + '-' + Moth + '-' + Day + ' ' + Hour +':'+ Minute  + ':' + Sechond;
    },
  },
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 25px;
    .item{
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 10px;
      box-sizing: border-box;
      .top{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        img{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
        p{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          span{
            color: #b3b3b3;
            font-size: 13px;
            margin: 2px 5px 2px 0;
          }
        }
      }
      .bottom{
        background-color: #fff;
        padding: 10px;
        text-align: justify;
        font-size: 14px;
        border-radius: 5px;
        white-space: pre-wrap;
      }
    }
    .nodata {
      font-size: 14px;
      color: #8c99a5;
      padding: 10px;
      text-align: center;
    }
  }
  .scroll-item::-webkit-scrollbar{
    width: 2px;
  }
  .scroll-item::-webkit-scrollbar-thumb {
    background-color: #fb5c76;
    border-radius: 2em;
  }
  .scroll-item::-webkit-scrollbar-track {
    background-color: #b6bfc2;
    border-radius: 2em;
  }
  @media screen and (max-width: 500px) {
    .box{
      width: 100%;
      height: 250px;
      .item{
        .bottom{
          background-color: #f7f8fa;
        }
      }
    }
  }
</style>