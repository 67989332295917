<template>
  <!-- :class="developClass ? 'newBox' : ''" -->
  <div class="box">
    <div class="teacherBox">
      <teacherInt></teacherInt>
    </div>
    <swiper v-if="onceOrmore" ref="mySwiper" :options="swiperOption"> 
      <div class="swiper-slide" v-for="(item,i) in swiperData" :key="i" @click="jumpClick(item.url)">
        <img :src="item.imgUrl" />
      </div>
      <!-- 分页器 -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="imgData" v-else>
      <img :src="imgData.imgUrl" alt="" @click="jumpClick(imgData.url)">
    </div>
  </div>
</template>

<script>
import { zhiboBanner } from '../../../../../api/info';
import teacherInt from './teacherInt.vue';
export default {
  name:'swiperBanner',
  components:{
    teacherInt,
  },
  data(){
    return{
      swiperData:'',
      imgData:'',
      // swiper 参数
      swiperOption: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
          //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          // dynamicBullets: true,
          // type:'custom',
          // renderCustom: function (swiper, current, total) {
          //   if(current === 1){
          //     return '<span class="swiperOne"><li><span></span></li><span class="swiperOneSpan"></span><span></span></span>';
          //   }else if(current === 2){
          //     return `<span class="swiperTwo"><span></span><li><span></span></li><span></span></span>`;
          //   }else{
          //     return `<span class="swiperThree"><span></span><span class="swiperThreeSpan"></span><li><span></span></li></span>`;
          //   }
          // },
        },
      },
      developClass:false,
      onceOrmore:false,
    }
  },
  created(){
    this.init(33);
  },
  computed:{
    packOrDevelop(){
      return this.$store.state.home.packOrDevelop
    }
  },
  watch:{
    'packOrDevelop'(newVal){
      if(newVal){
        this.developClass = true;
        this.init(41);
      }else{
        this.developClass = false;
        this.init(33);
      }
    }
  },
  methods:{
    // 获取swiper
    init(attr){
      const option = {
        languageidentification:1,
        type:attr,
        region:1
      }
      zhiboBanner(option).then(res=>{
        if(res.Status === 0){
          if(res.Data.length>1){
            this.swiperData = res.Data;
            this.onceOrmore = true;
          }else{
            this.imgData = res.Data[0];
            this.onceOrmore = false;
          }
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // swiper跳转链接
    jumpClick(attr){
      if(attr){
        window.open(attr);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  width: 100%;
  height: 216px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  // margin: 10px auto;
  border-radius: 5px;
  .imgData{
    width: 35%;
    height: 100%;
    box-shadow: 0px 5px 5px #c3c6c8;
    border-radius: 5px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      border-radius: 5px;
    }
  }
  .swiper-container{
    width: 35%;
    height: 100%;
    box-shadow: 0px 5px 5px #c3c6c8;
    border-radius: 5px;
    .swiper-wrapper{
      .swiper-slide{
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
    /deep/.swiper-pagination{
      .swiper-pagination-bullet-active{
        background: #d52226 !important;
      }
      .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
      }
    }
  }
  .teacherBox{
    width: 65%;
    background-color: #ffffff;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 5px 5px #c3c6c8;
    margin-right: 10px;
  }
}
.newBox{
  // height: 122px;
  // .imgData{
  //   width: 49.5%;
  // }
  // .swiper-container{
  //   width: 49.5%;
  // }
  // .teacherBox{
  //   width: 49.5%;
  //   height: 100%;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  //   &::-webkit-scrollbar{
  //     display: inline-block;
  //     width: 6px !important;
  //     height: 6px !important;
  //     background-color: #f1f1f1;
  //     background-clip: content-box;
  //     scroll-behavior: smooth;
  //   }
  //   &::-webkit-scrollbar-thumb{
  //     border-radius: 5px;
  //     width: 5px;
  //     background-color: #c3c3c3;
  //   }
  //   .big {
  //     height: auto;
  //     .introduce {
  //       height: auto;
  //     }
  //   }
  // }
}
</style>