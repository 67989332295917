<template>
  <div class="box">
    <!-- <img class="closerTable" @click="controlTable" src="../assets/images/user-info-close.png" alt=""> -->
    <div class="title">
      <!-- <p class="titlep">{{timeTableContent[0] ? timeTableContent[0].broom.name : ''}}</p> -->
      <!-- <ul>
        <li v-for="item in week" :key="item.id">
          <span class="otherWeek" :class="item.id == today ? 'todayWeek' : ''">{{item.name}}</span>
          <br>
          <span class="weekname">{{item.data}}</span>
          <div class="contentBox" v-for="tab in timeTableContent" :key="tab.id">
            <div class="itemBox" :class="tab.isplay == true ? 'itemBoxRed' : ''" v-if="tab.day2week == item.id">
              <p class="className">{{tab.bclass.name}}</p>
              <span class="btype voice" v-if="tab.bclass.btype == 1">语音</span>
              <span class="btype video" v-if="tab.bclass.btype == 2">视频</span>
              <p class="bottom">
                <span>{{insertStr(tab.intime,-2,':')}}-{{insertStr(tab.outime,-2,':')}}</span>
                <span>{{tab.bclass.tname}}</span>
              </p>
              <p v-if="tab.isplay == true" class="once">
                直播中
                <img src="../assets/images/craig/slider/gif.gif" alt="">
              </p>
            </div>
          </div>
        </li>
      </ul> -->

    </div>
    <div class="weekname">
      <p @click="pClick(1)" :class="controlClass ===1 ? 'p_other' : '' ">星期一<br><span>{{weekDate[0]}}</span></p>
      <p @click="pClick(2)" :class="controlClass ===2 ? 'p_other' : '' ">星期二<br><span>{{weekDate[1]}}</span></p>
      <p @click="pClick(3)" :class="controlClass ===3 ? 'p_other' : '' ">星期三<br><span>{{weekDate[2]}}</span></p>
      <p @click="pClick(4)" :class="controlClass ===4 ? 'p_other' : '' ">星期四<br><span>{{weekDate[3]}}</span></p>
      <p @click="pClick(5)" :class="controlClass ===5 ? 'p_other' : '' ">星期五<br><span>{{weekDate[4]}}</span></p>
    </div>
    <div v-if="finalResult[0]">
      <div class="weekContent" v-for="item in finalResult[0].list" :key="item.id">
        <p class="contentTop">
          <span>{{item.bclass.name}}</span>
          <span style="color: #999;margin-left: 10px;">{{item.bclass.tname}}</span>
        </p>
        <div class="contentBottom">
          <div class="cbLeft">
            <span class="btype voice" v-if="item.bclass.btype == 1">语音</span>
            <span class="btype video" v-if="item.bclass.btype == 2">视频</span>
            <span class="cbtime">{{insertStr(item.intime,-2,':')}}-{{insertStr(item.outime,-2,':')}}</span>
          </div>
          <!-- <p v-if="item.isplay == true" class="once">
            直播中
            <img src="../assets/images/craig/slider/gif.gif" alt="">
          </p> -->
          <img v-if="item.isplay == true" src="../assets/images/craig/slider/nowLive.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'timeTable',
  data(){
    return{
      // 根据当前时间获取本周每天对应的日期
      weekDate:[],
      // 控制p标签样式
      controlClass:1,
      // 处理后的课表内容
      finalResult:[],
    }
  },
  created(){
    this.init();
    this.getWeek();
  },
  mounted(){
    setTimeout(() => {
      this.$nextTick(()=>{
        this.todayTimeTable();
      })
    }, 800);
  },
  computed:{
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    timeTableContent(){
      const info = this.$store.state.home.timeTableContent
      if(info){
        const newlist = this.getListGroupBy(info,'day2week')
        return newlist
      }
    },
  },
  methods:{
    // 获取课表接口
    async init(){
      const option = {
        platid:1,
        'broom.id':this.roomInfo.id,
        sort:'day2week,asc',
      }
      await this.$store.dispatch('home/getTimetable', option)
    },
    controlTable(){
      this.$store.commit('home/set_timeTable',false);
    },
    insertStr(source, start, newStr) {
      return String(source).slice(0, start) + newStr + String(source).slice(start)
    },
    // 页面刚进入，通过获取当天为星期几，获取相应的课表内容
    todayTimeTable(){
      if(this.timeTableContent){
        const attr  = new Date().getDay();
        this.pClick(attr);
        this.timeTableContent.forEach(element => {
          if(element.day2week == attr){
            this.finalResult.push(element);
          }
        });
      }
    },
    // 根据某一天日期，获取该日所在周的所有日期
    getWeek() {
      // 一天里一共的毫秒数
      let oneDayTime = 1000 * 60 * 60 * 24 
      let today = new Date()
      // 若那一天是周末时，则强制赋值为7
      let todayDay = today.getDay() || 7 
      let startDate = new Date(
        today.getTime() - oneDayTime * (todayDay - 1)
      )
      for(let i = 0; i < 7; i++){
        let temp = new Date(startDate.getTime() + i * oneDayTime)
        let year = temp.getFullYear();
        let month = temp.getMonth() + 1;
        if(month<10){
          month = '0'+month;
        }
        let day = temp.getDate();
        if(day<10){
          day = '0'+day;
        }
        this.weekDate[i] = `${month}-${day}` 
      }
      return this.weekDate;
    },
    // 点击选择哪一天
    pClick(attr){
      switch(attr){
        case 1:
          this.controlClass = 1;
          break
        case 2:
          this.controlClass = 2;
          break
        case 3:
          this.controlClass = 3;
          break
        case 4:
          this.controlClass = 4;
          break
        case 5:
          this.controlClass = 5;
          break
      };
      this.finalResult = [];
      this.timeTableContent.forEach(element => {
        if(element.day2week == attr){
          this.finalResult.push(element);
        }
      });
    },
    // 将后台返回的对象数组根据某个相同字段组成新的对象数组
    getListGroupBy(list, key) {
      var keysArr = list.map(item=>item[key])
      var keys = [...new Set(keysArr)]
      var newList = keys.map(item=> {
        return {
          [key]: item,
          list: list.filter(i=>i[key] == item)
        }
      })
      return newList
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 290px;
    // height: 650px;
    background-color: #E9F5FE;
    box-sizing: border-box;
    padding: 20px 0px;
    position: relative;
    .weekname{
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 20px;
      p{
        color: #999999;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        span{
          font-size: 15px;
          margin-top: 3px;
          display: inline-block;
        }
      }
      .p_other{
        color: #333;
      }
    }
    .weekContent{
      color: #334350;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 15px 20px;
      border-top: 1px solid #D8D8D8;
      .contentTop{
        width: 100%;
        font-size: 14px;
      }
      .contentBottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        .cbLeft{
          display: flex;
          justify-content: center;
          align-items: center;
          .btype{
            width: auto;
            box-sizing: border-box;
            padding: 2px 10px 3px 10px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            border: 1px solid #158AEA;
          }
          .voice{
            color: #158AEA;
          }
          .video{
            color: #158AEA;
          }
          .cbtime{
            color: #999999;
            font-size: 13px;
            margin-left: 10px;
          }
        }
      }
    }
    .weekContent:last-child{
      border-bottom: 1px solid #D8D8D8;
    }
  }
</style>