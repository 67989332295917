<template>
  <div class="stop-words-item">
    <div slot="title" class="stop-word-header">用户禁言</div>
    <el-form
      ref="stopWordForm"
      :model="reqData"
      :rules="stopWorFormRules"
      class="stop-word-form"
      label-position="left"
      label-width="100px"
    >
      <el-form-item
        label="禁言时长"
        prop="forbidtimelong"
        class="stop-word-form-item"
      >
        <el-select v-model="reqData.forbidtimelong">
          <el-option v-for="item in forbidtimelist" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="禁言原因"
        prop="forbidreasontype"
        class="stop-word-form-item"
      >
        <el-select v-model="reqData.forbidreasontype">
          <el-option v-for="item in forbidreasonlist" :key="item.id" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="reqData.forbidreasontype === 4"
        label="禁言理由"
        prop="forbidreason"
        class="stop-word-form-item"
        style="width: 320px;"
      >
        <el-input
          v-model="reqData.forbidreason"
          type="textarea"
          :row="6"
        />
      </el-form-item>
      <el-form-item label-width="0" style="text-align: center;">
        <button class="sotp-common-btn sure-btn" @click.stop="handleSubmit">确定</button>
        <button class="sotp-common-btn cancel-btn" @click.stop="handleStopWordCancel">取消</button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reqData: {
        forbidtimelong: '',
        forbidreasontype: '',
        forbidreason: ''
      },
      stopWorFormRules: {
        forbidtimelong: [{ required: true, message: '禁言时长不能为空', trigger: 'change' }],
        forbidreasontype: [{ required: true, message: '禁言原因不能为空', trigger: 'change' }]
      }
    }
  },
  computed: {
    socket() {
      return this.$store.state.home.socket
    },
    stopWordsUserInfo() {
      return this.$store.state.home.stopWordsUserInfo
    },
    forbidtimelist() {
      return this.$store.state.home.forbidtimelist
    },
    forbidreasonlist() {
      return this.$store.state.home.forbidreasonlist
    }
  },
  methods: {
    // 确认禁言用户
    handleSubmit() {
      this.reqData.id = this.stopWordsUserInfo.sid
      this.$refs['stopWordForm'].validate(valid => {
        if (valid) {
          this.$store.dispatch('home/handleForbidEvent', this.reqData).then(res => {
            if (res.Status === 0) {
              this.$message({
                type: 'success',
                message: '操作成功'
              })
              this.$refs['stopWordForm'].resetFields()
              this.$store.commit('home/set_dialogobj', {
                dialogVisible: false,
                isCloseIcon: true
              })
            } else {
              this.$message({
                type: 'error',
                message: '操作失败'
              })
            }
          })
        }
      })
    },
    // 取消禁言
    handleStopWordCancel() {
      this.$refs['stopWordForm'].setFields()
      this.$store.commit('home/set_dialogobj', {
        dialogVisible: false,
        isCloseIcon: true
      })
    }
  }
}
</script>

<style lang="less">
.stop-words-item {
  .stop-word-header {
    font-size: 16px;
    color: #ee2547;
    margin-top: -50px;
    line-height: 40px;
    border-bottom: 1px solid #d0dbe3;
    padding-left: 10px;
  }
  .stop-word-form {
    width: 380px;
    margin: 30px auto;
    .stop-word-form-item {
      .el-input__inner{
        height: 35px;
        line-height: 35px;
      }
      .is-focus .el-input__inner{
        border-color: #ee2547;
      }
      .el-form-item__label {
        text-align: center !important;
        color: #394d62;
        font-size: 14px;
      }
      .el-select-dropdown__item.selected{
        color: #ee2547;
      }
    }
    .sotp-common-btn {
      width: 110px;
      height: 36px;
      font-size: 14px;
      border-radius: 4px;
      margin-top: 15px;
      line-height: 36px;
    }
    .sure-btn {
      color: #ffffff;
      background: #ee2547;
      margin-right: 20px;
      border: none;
    }
    .cancel-btn {
      color: #ee2547;
      border: 1px solid #ee2547 !important;
      background: #ffffff;
    }
  }
}
.el-select-dropdown__item.selected{
  color: #ee2547 !important;
}
.el-select .el-input__inner:focus{
  border-color: #ee2547;
}
</style>
