<template>
  <el-header class="app-header" :class="packOrDevelop ? 'app-header-other' : ''">
    <div class="headerLeft">
      <websocket-vue></websocket-vue>
      <advert-right></advert-right>
    </div>
    <div class="header-right">
      <!-- 登录和欢迎文字二者只能有一个存在 -->
      <template>
        <!-- <button
          v-if="!userInfo.id"
          class="login-btn"
          size="small"
          @click.stop="handleRegister"
        >
          注册
        </button> -->
        <button
          v-if="!userInfo.id || (userInfo.id && userInfo.nick.indexOf('游客') !== -1)"
          class="login-btn"
          size="small"
        >
          <span @click.stop="handleLogin(1)">登录</span><span @click.stop="handleLogin(2)">免费注册</span>
        </button>

        <!-- <span v-if="userInfo.id" class="login-text">
          {{ userInfo.nick || userInfo.whoami }}，欢迎进入
        </span> -->
        <div class="headerBox" v-if="userInfo.id && userInfo.nick.indexOf('游客') == -1">
          <img @click="ulSelect = !ulSelect" @error="imgLoadError" class="showHeaderImg" :src="control.headerImg ? control.headerImg : control.imgSrc[0]" alt="">
          <ul v-if="ulSelect">
            <li>
              <img :src="control.headerImg ? control.headerImg : control.imgSrc[0]" alt="">
              <span v-if="userInfo.utype == 0">{{ userInfo.nick }}</span>
              <span v-else>{{ userInfo.whoami }}</span>
            </li>
            <li @click="goUser">
              <span>用户中心</span>
              <img src="../assets/images/craig/avatar/user.png" alt="">
            </li>
            <li @click="()=>{control.showDiv = ! control.showDiv; ulSelect = !ulSelect}">
              <span>更换头像</span>
              <img src="../assets/images/craig/avatar/changeavatar.png" alt="">
            </li>
            <li @click="()=>{control.showNickDiv = ! control.showNickDiv; ulSelect = !ulSelect}">
              <span>设置昵称</span>
              <img src="../assets/images/craig/avatar/nick.png" alt="">
            </li>
            <li @click.stop="handleLoginout">
              <span>退出登录</span>
              <img src="../assets/images/craig/avatar/logout.png" alt="">
            </li>
          </ul>
        </div>
        <div class="positionBox" v-if="control.showDiv">
          <img @click="()=>{control.showDiv = ! control.showDiv}" class="closer" src="../assets/images/craig/closer.png" alt="">
          <div class="redTop">
            <img :src="control.headerImg ? control.headerImg : control.imgSrc[0]" alt="">
            <span>当前头像</span>
          </div>
          <p>
            <img v-for="(item,i) in control.imgSrc" :key="i" :class="control.target == i ? 'borderImg' : ''" @click="tag(i,item)" :src="item" alt="">
          </p>
          <div class="uploadBox">
            <!-- 
              之前上传接口
              http://manage.huiadmin.lan/apis/api/users/upload

              https://testwww.rlc525.com/apis/api/users/upload
              
             -->
            <el-upload
              class="avatar-uploader"
              action="https://www.rlcproltd.net/apis/api/users/upload"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUpload" :src="imageUpload" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <span class="changeButton" @click="changeImg">更换头像</span>
        </div>
        <div class="nickNameBox" v-if="control.showNickDiv">
          <img @click="()=>{control.showNickDiv = ! control.showNickDiv}" class="closer" src="../assets/images/teacherJs/cancelGrey.png" alt="">
          <div>
            <span class="nickTitle">请输入昵称</span>
            <input v-model="nickData" type="text" placeholder="请输入昵称">
            <p>
              <span @click="()=>{control.showNickDiv = ! control.showNickDiv}">取消</span>
              <span @click="submit">确定</span>
            </p>
          </div>
        </div>
        <!-- <button
          v-if="userInfo.id && userInfo.nick.indexOf('游客') == -1"
          class="down-broadcast-btn"
          size="small"
          @click.stop="handleLoginout"
        >
          退出登录
        </button> -->
      </template>
      <!-- 导师登录成功并且选择进入了某个房间，才会出现上下播按钮（登录成功，导师，房间） -->
      <template v-if="userInfo.id && userInfo.utype === 1">
        <button
          v-if="roomInfo.id && !isplay"
          class="up-broadcast-btn"
          size="small"
          @click.stop="handleUpPlay"
        >
          上播
        </button>
        <button
          v-if="roomInfo.id && isplay"
          class="down-broadcast-btn"
          size="small"
          @click.stop="handleDownPlay"
        >
          下播
        </button>
      </template>
    </div>
    <!-- <img class="more_menu" @click="controlMenu" src="../assets/images/more_gif.gif" alt=""> -->
    <div v-if="control.showDiv || control.showNickDiv" class="shadow"></div>
  </el-header>
</template>

<script>
import { setSessionStorage } from '../utils/tool'
import { PMSetUserNick, PMSetUserHead } from '../api/login'
import websocketVue from './websocket.vue';
import advertRight from './advertRight.vue';
export default {
  components:{
    websocketVue,
    advertRight,
  },
  data(){
    return{
      // 控制头像相关的data
      control:{
        imgSrc:[
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093710877.png?Expires=3575237832&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=%2BRjt%2BVRwRICB4Kfiu4GG7OpodbE%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093803982.png?Expires=3575237883&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=nAewhZXk7T%2F2VgT8dxzjmEmPsR8%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093819552.png?Expires=3575237899&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=MtuN6XU9jHvVJ4EFGHsW1FFol9Y%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094152783.png?Expires=3575238113&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=Td4F3uN87i6XCLw6DDjr%2BSrVU4k%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/2023050309420737.png?Expires=3575238127&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=Cdsn%2FfMnd%2BfFWLWPMb%2BbDKHgkts%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094222222.png?Expires=3575238142&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=l%2FH2QuQYK3WEFepGX1pmBnQtNx0%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094236953.png?Expires=3575238156&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=zy7Dkcswr6MfswQK2sI7zWBRAVo%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094251148.png?Expires=3575238171&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=AJCEYC6LZuI%2FYeijWDfJw%2FBK5no%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094313405.png?Expires=3575238194&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=CDBMCkky3PkdjTo0ooh6YnDW1Fs%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094327782.png?Expires=3575238207&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=9Dko4wYiOLs%2FPSDP7zmbfNb%2FT%2Bc%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094341776.png?Expires=3575238221&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=oHN1xo5xdCCZ3nvn99Au9aOOGHg%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094625773.png?Expires=3575238385&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=NTVohNSHp%2BQUr7MFPjARAyKuZ4Y%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094710620.png?Expires=3575238430&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=skfQtAhBJi%2FxX5VS%2BbsHBAyJ0xI%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094735379.png?Expires=3575238455&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=7LKwYM%2FXFpkXoEUGXHvG2OLbLEc%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094458816.png?Expires=3575238298&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=RBwN%2Ft5j0nBOAt0Mg2olx7OAjbg%3D',
          'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503094516819.png?Expires=3575238316&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=O%2FNMh5uc%2Bd8SyTnMNjbGcAXBkn4%3D'
        ],
        showDiv:false,
        target:'',
        realImg:'',
        headerImg:null,
        showNickDiv:false,
      },
      ulSelect:false,
      nickData:'',
      // 上传的头像
      imageUpload: '',
      // 绑定在upload组件的对象
      headers:{Authorization:''},
    }
  },
  created(){
    if(sessionStorage.getItem('headerImg')){
      this.control.headerImg = sessionStorage.getItem('headerImg');
    }
  },
  mounted(){
    // 上传图片时需要的token
    this.headers.Authorization = JSON.parse(sessionStorage.getItem('token')).token;
    if(this.userInfo.avatar){
      this.control.headerImg = this.userInfo.avatar;
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.login.userInfo || {}
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    dialogObj() {
      return this.$store.state.home.dialogObj
    },
    isplay() {
      console.log(this.$store.state.home.isplay);
      return this.$store.state.home.isplay
    },
    currentRoomUserList() {
      return this.$store.state.home.currentRoomUserList
    },
    socket() {
      return this.$store.state.home.socket
    },
    packOrDevelop(){
      return this.$store.state.home.packOrDevelop
    },
    isReload(){
      return this.$store.state.home.isReload
    }
  },
  watch:{
    'isReload'(){
      this.$store.commit('home/set_ckplayerObject',{video: this.roomInfo.param.pcurl})
      console.log('重新给直播流赋值');
    }
  },
  methods: {
    // 头像加载失败事件
    imgLoadError(e) {
      let img = e.srcElement;
	    img.src = 'https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093710877.png?Expires=3575237832&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=%2BRjt%2BVRwRICB4Kfiu4GG7OpodbE%3D';
	    img.onerror = null; //防止闪图
    },
    // 注册按钮
    // handleRegister() {
    //   this.$store.commit('home/set_dialogobj2', {
    //     dialogVisible: true,
    //     isCloseIcon: true,
    //     type: 1,
    //     width: '500px'
    //   })
    // },
    // 登录按钮
    handleLogin(attr) {
      this.$store.commit('home/set_dialogobj', {
        dialogVisible: true,
        isCloseIcon: true,
        type: 1,
        width: '500px'
      })
      if(attr === 1){
        this.$store.commit('login/set_activeName', 'second');
      }else{
        this.$store.commit('login/set_activeName', 'first');
      }
    },
    // 退出登录
    handleLoginout() {
      // 房间中有多个讲师的时候之后一个讲师退出时提示下播
      const list = []
      this.currentRoomUserList.length && this.currentRoomUserList.forEach(item => {
        if (item.utype === 1) {
          list.push(item)
        }
      })
      // 首先判断是客服还是老师是否在直播中,老师分为上播和下播状态。上播中：提示老师先下播在退出；老师下播和客服：样直接退出
      if (this.userInfo.utype === 1 && this.isplay && list.length === 1) {
        this.$message({
          type: 'warning',
          message: '正在上播中，请下播后再退出登录'
        })
      } else {
        // 直接退出：清除token,userInfo,roomInfo
        setSessionStorage('token', { token: '' })
        setSessionStorage('userInfo', { id: '' })
        setSessionStorage('roomInfo', { id: '' })
        sessionStorage.removeItem('onlyOnce');
        location.reload()
      }
    },
    // 上播
    handleUpPlay() {
      // 判断用户是否已经登录，并且是老师身份，房间id不能为空，配置的播放流地址不能为空（pcurl、mobileurl）
      if (this.userInfo.id && this.userInfo.utype === 1 && this.roomInfo.id && this.roomInfo.param.mobileurl && this.roomInfo.param.pcurl) {
        // 上播需要给socket发消息，通知客户端（APP和PC端）将房间的视频播放地址改为直播流地址(在socket中修改地址)
        const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        const option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 15,
          param: {
            TXT: '老师开始直播了，请注意课堂纪律'
          }
        }
        this.socket.send(JSON.stringify(option))
      }
    },
    // 下播
    handleDownPlay() {
      // 讲师下播需要给socket发消息，通知客户端（APP和PC端）将房间的视频播放地址改为默认视频播放地址(在socket中修改地址)
      const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
      const option = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        type: 16,
        param: {
          TXT: '老师直播结束了'
        }
      }
      this.socket.send(JSON.stringify(option))
    },
    // 点击头像事件
    tag(q,m){
      this.control.target = q;
      this.control.realImg = m;
    },
    // 点击更换头像
    changeImg(){
      let data = {
        id: this.userInfo.id,
        avatar: this.imageUpload ? this.imageUpload : this.control.realImg
      }
      // 判断是上传头像还是本地头像
      if(this.imageUpload){
        sessionStorage.setItem('headerImg',this.imageUpload);
      }else{
        sessionStorage.setItem('headerImg',this.control.realImg);
        this.control.headerImg = this.control.realImg;
      }
      this.$store.dispatch('home/nickAvatar', data);
      PMSetUserHead({
        Uid: this.userInfo.Uid,
        HeadSculpture: data.avatar
      }).then(res => {
        console.log(res)
      })
      this.control.showDiv = false;
    },
    // 点击出现右侧菜单栏
    controlMenu(){
      this.$store.commit('home/set_rightMenu',true);
    },
    // 跳转用户中心
    goUser(){
      window.open('https://www.rlcproltd.net/uc/userCenter');
    },
    // 提交昵称
    submit(){
      if(!this.nickData){
        this.$message({
          type: 'warning',
          message: '请输入昵称!'
        })
      }else{
        const data = {
          id:this.userInfo.id,
          nick:this.nickData
        }
        this.$store.dispatch('home/nickAvatar', data);
        PMSetUserNick({
          Uid: this.userInfo.Uid,
          Nickname: this.nickData
        }).then(res => {
          console.log(res)
        })
        this.control.showNickDiv = false
      }
    },
    // 上传头像成功
    handleAvatarSuccess(res, file) {
      if(file.response.Status == 0){
        this.imageUpload = file.response.url;
      }else{
        this.$message.error('图片上传失败!');
      }
    },
    // 上传头像之前
    beforeAvatarUpload(file) {
      const isJPG = file.type == 'image/jpeg' || 'image/png' || 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('请上传正确图片格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
}
</script>

<style lang="less" scoped>
.app-header {
  width: 100%;
  position: relative;
  // background: #ffffff;
  // box-shadow: 0px 5px 20px #c3c6c8;
  height: 40px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 15px !important;
  // margin: 6px auto;
  .headerLeft{
    width: 1165px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button {
    width: 80px;
    height: 26px;
    border-radius: 13px;
    cursor: pointer;
  }
  .header-right {
    width: 500px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    font-size: 14px;
    background-color: #ffffff;
    .headerBox{
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .showHeaderImg{
        width: 35px;
        height: 35px;
        cursor: pointer;
      }
      ul{
        width: 210px;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        box-shadow: 0 0 5px #cdcdcd;
        position: absolute;
        z-index: 7;
        top: 38px;
        right: -10px;
        li{
          width: 80%;
          margin: 0 auto;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 45px;
        }
        li:nth-child(1){
          justify-content: flex-start;
          margin: 15px auto;
          img{
            width: 35px;
          }
          span{
            color: #717171;
            margin-left: 15px;
          }
        }
      }
    }
    .positionBox{
      width: 550px;
      height: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 1000;
      background-color: #ffffff;
      box-sizing: border-box;
      padding-bottom: 25px;
      border-radius: 5px;
      .closer{
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
      .redTop{
        width: 100%;
        height: 135px;
        background-color: #ee2547;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
          width: 50px;
          margin-bottom: 10px;
        }
        span{
          color: #ffffff;
        }
      }
      p{
        width: 55%;
        flex-wrap: wrap;
        margin: 20px auto 0;
        img{
          width: 50px;
          margin: 12px;
          cursor: pointer;
        }
        .borderImg{
          border: 2px solid #ee2547;
          border-radius: 50%;
        }
      }
      .changeButton{
        width: 30%;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ee2547;
        border-radius: 3px;
        color: #ffffff;
        cursor: pointer;
      }
      .uploadBox{
        margin-bottom: 20px;
        .avatar-uploader .el-upload {
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }
        .avatar-uploader-icon {
          font-size: 22px;
          color: #8c939d;
          width: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
        }
        .avatar {
          width: 50px;
          height: 50px;
          display: block;
        }
      }
    }
    .nickNameBox{
      width: 550px;
      height: 360px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      background-color: #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      .closer{
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
      div{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .nickTitle{
          font-size: 16px;
          color: #5f5f5f;
        }
        input{
          width: 60%;
          height: 45px;
          border: 1px solid#cdcdcd;
          border-radius: 5px;
          margin: 40px auto;
          text-indent: 40%;
          color: #000;
        }
        p{
          width: 61%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span{
            width: 150px;
            height: 38px;
            text-align: center;
            line-height: 38px;
            border: 1px solid #f2261f;
            border-radius: 5px;
            color: #f2261f;
            font-size: 16px;
            cursor: pointer;
          }
          span:nth-child(2){
            background-color: #f2261f;
            color: #fff;
          }
        }
      }
    }
    .login-btn,
    .up-broadcast-btn {
      background: none;
      border: none;
      width: 150px;
    }
    .login-btn{
      // color: #e90014;
      width: auto;
      span {
        display: inline-block;
        padding: 5px 18px;
        border-radius: 4px;
        &:nth-child(1) {
          border: 1px solid #2FAEF8;
          color: #2FAEF8;
          margin-right: 11px;
        }
        &:nth-child(2) {
          background-color: #158AEA;
          color: #fff;
        }
      }
    }
    .down-broadcast-btn {
      background: #ee2547;
      cursor: pointer;
      margin-right: 8px;
      border: none;
      color: #ffffff;
    }
    .login-text {
      color: #96a3b0;
      display: inline-block;
      margin-right: 10px;
    }
  }
  .shadow{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.4);
  }
}
.app-header-other{
  .headerLeft{
    width: 1385px;
  }
}
.el-header{
  padding: 0;
}
</style>
