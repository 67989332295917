<template>
  <div class="box">
    <div class="wssBox">
      <div>
        <ul>
          <li class="liFirst">
            <span>伦敦金</span>
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPic ? 'classRed' : 'classGreen' ">{{London.gold}}</span>
              <img :src="London.controlPic ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
          </li>
          <p class="pThird">
            <span>{{London.goldRise}}</span>
            <!-- <span>{{London.goldPer}}</span> -->
          </p>
        </ul>
        <label for=""></label>
        <ul>
          <li class="liFirst">
            <span>伦敦银</span>
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPicOther ? 'classRed' : 'classGreen' ">{{London.silver}}</span>
              <img :src="London.controlPicOther ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
          </li>
          <p class="pThird">
            <span>{{London.silverRise}}</span>
            <!-- <span>{{London.silverPer}}</span> -->
          </p>
        </ul>
        <label for=""></label>
        <ul>
          <li class="liFirst">
            <span>美元指数</span>
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPicDollar ? 'classRed' : 'classGreen' ">{{London.dollar}}</span>
              <img :src="London.controlPicDollar ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
          </li>
          <p class="pThird">
            <span>{{London.dollarRise}}</span>
          </p>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {analyticalData} from '../../../../../utils/tools';
export default {
  name:'websocket',
  data(){
    return{
      LondonImg:[
        require('../../../../../static/index/jiaohu/up.png'),
        require('../../../../../static/index/jiaohu/down.png'),
      ],
      London:{
        gold: '--',
        silver: '--',
        dollar: '--',
        goldRise: '0.0',
        silverRise: '0.0',
        dollarRise: '0.0',
        goldPer: '0.00%',
        silverPer: '0.00%',
        dollarPer: '0.00%',
        controlPic: true,
        controlPicOther: true,
        controlPicDollar: true,
      },
      goldCl:'',
      silverCl:'',
      dollarCl:'',
    }
  },
  created(){
    // 初始化websocket
    this.initWebSocket('wss://www.rlcproltd.net/quote');
  },
  methods:{
    // websocket 方法
    initWebSocket(src) {
      // const wsuri = "wss://tradeapi-cy1.mcrubly.com:4433/quote";
      const wsuri = src;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      let actions = { 't': 7, 'd': { 'k': 0 , "nq":true}};
      this.websocketsend(JSON.stringify(actions));
      let actions2 = { "t":21,"d":["GOLD","SILVER","DollarIndex"]}
      this.websocketsend(JSON.stringify(actions2));
    },
    websocketonerror() {
      let _this = this;
      console.log("连接建立失败");
      this.initWebSocket('wss://www.rlcproltd.net/quote');
    },
    websocketonmessage(e) {
      const data = JSON.parse(e.data)
      if (data.t === 0) {
        for (const iterator of data.d) {
          const blob = window.atob(iterator.q)
          const symbol = analyticalData(data.d[0].q||"",5);
          // console.log(symbol);
          if(symbol.SymbolName.slice(0,4) == 'GOLD'){
            // 伦敦金
            this.London.gold = symbol.Bid.toFixed(3);
            let gold = symbol.Bid;
            if(Number(gold) > Number(this.London.gold)){
              this.London.controlPic = true;
            }else{
              this.London.controlPic = false;
            }
          }else if(symbol.SymbolName.slice(0,6) == 'SILVER'){
            // 伦敦银
            this.London.silver = symbol.Bid.toFixed(3);
            let silver = symbol.Bid;
            if(Number(silver) > Number(this.London.silver)){
              this.London.controlPicOther = true;
            }else{
              this.London.controlPicOther = false;
            }
          }else if(symbol.SymbolName.slice(0,6) == 'Dollar'){
            // 美元现金
            this.London.dollar = symbol.Bid.toFixed(3);
            let dollar = symbol.Bid;
            if(Number(dollar) > Number(this.London.dollar)){
              this.London.controlPicDollar = true;
            }else{
              this.London.controlPicDollar = false;
            }
          }
        }
      }else if (data.t === 5) {
        // console.log('t=5');
        let open = data.d.filter(item => {
          if (/(GOLD|SILVER|DollarIndex)/.test(item.n)) {
            return item.n
          }
        })
        open.forEach(element => {
          if(element.n == 'GOLD'){
            // 伦敦金
            this.goldCl =  element.cl;
          }else if(element.n == 'SILVER'){
            // 伦敦银
            this.silverCl = element.cl;
          }else if(element.n == 'DollarIndex'){
            // 美元指数
            this.dollarCl = element.cl;
          }
        });
      }
      // 伦敦金 计算涨幅;
      this.London.goldRise = (Number(this.London.gold) - Number(this.goldCl)).toFixed(2);
      if(this.London.goldRise == 'NaN'){
        this.London.goldRise = ''
      }
      // 计算百分比（涨幅/昨收）
      this.London.goldPer = ((this.London.goldRise/this.goldCl)*100).toFixed(2)+ '%';
      // 伦敦银 涨幅
      this.London.silverRise =  (Number(this.London.silver) - Number(this.silverCl)).toFixed(3);
      if(this.London.silverRise == 'NaN'){
        this.London.silverRise = ''
      }
      // 计算百分比（涨幅/昨收）
      this.London.silverPer = ((this.London.silverRise/this.silverCl)*100).toFixed(2)+ '%';
      // 美元指数 计算涨幅;
      this.London.dollarRise = (Number(this.London.dollar) - Number(this.dollarCl)).toFixed(2);
      if(this.London.dollarRise == 'NaN'){
        this.London.dollarRise = ''
      }
      // 计算百分比（涨幅/昨收）
      this.London.dollarPer = ((this.London.dollarRise/this.dollarCl)*100).toFixed(2)+ '%';
    },
    websocketsend(Data) {
      this.websock.send(Data);
    },
    websocketclose(e) {
      console.log("断开连接", e);
    },
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 74%;
    box-shadow: 0px 5px 20px #c3c6c8;
    background: #ffffff;
    border-radius: 5px;
    .wssBox{
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        ul{
          // width: 350px;
          background-size: cover;
          display: flex;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          margin: 0;
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .liFirst{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            margin-bottom: 2px;
            span{
              font-size: 15px;
              color: #000000;
            }
            p{
              margin-top: 5px;
              span{
                font-size: 13px;
                color: #6d6d6d;
                margin-right: 5px;
                letter-spacing: 0px;
              }
            }
          }
          .liSecond{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px;
              span{
                font-size: 15px;
                font-weight: 550;
                line-height: 25px;
              }
              .classRed{
                color: #f4382c;
              }
              .classGreen{
                color: #0fb255;
              }
              img{
                width: 5px;
                height: auto;
                margin-left: 3px;
              }
            }
            p:nth-child(2){
              margin-bottom: 10px;
              span{
                font-size: 14px;
                color: #6d6d6d;
                margin-right: 10px;
              }
            }
          }
          .pThird{
            margin: 0 0 0 10px;
            font-size: 13px;
            color: #666666;
          }
        }
        label{
          width: 1px;
          height: 26px;
          background-color: #eae9e9;
          margin: 0 -100px;
        }
      }
    }
  }
</style>