<template>
  <!-- :style="{backgroundImage:'url('+ this.teacherInfo[1].avatar +')'}" -->
  <div class="big" :class="packOrDevelop ? 'newBig' : '' ">
    <div v-if="!control" class="introduce">
      <!-- tab -->
      <!-- 左侧导航条 -->
      <div class="navList">
        <div v-for="item in navList" :key="item.index" :class="['navItem', navListActive === item.index ? 'navItem_active' : '']" @click="navItemClick(item)">
          {{ item.name }}
        </div>
      </div>
      <img v-if="!intoRoom && teacherInfo !== ''" class="teacherImg" :src="this.teacherInfo ? this.teacherInfo[1].bigimg : ''" alt="">
      <img v-if="intoRoom || (!intoRoom && teacherInfo == '')" src="../assets/images/teacherJs/teacher.png" alt="">
      <div class="introduceBox" v-if="navListActive === '1'">
        <!-- <img class="teaIntImg" src="../assets/images/craig/teaIntImg.png" alt=""> -->
        <p v-if="!intoRoom && teacherInfo !== ''">
          {{this.teacherInfo ? this.teacherInfo[1].intro : ''}}
        </p>
        <p v-if="intoRoom || (!intoRoom && teacherInfo == '')">
          产业经济专业，拥有十年投资经验，曾任职于知名交易所，对各类金融商品与财务规划皆有广泛的研究。曾在模拟交易大赛中创造超过900%的回报率，在选择权投资方面，更创造单日获利破百万的交易神话。
        </p>
        <!-- <p v-if="intoRoom || (!intoRoom && teacherInfo == '')">
          产业经济专业，拥有十年投资经验，取得CFP国际理财规划人员等多张金融证照；曾任职于知名交易所，对各类金融商品与财务规划皆有广泛的研究。曾在外汇模拟大赛中创造超过900%的回报率，在选择权投资方面，更创造单日获利破百万的交易神话。
        </p> -->
      </div>
      <!-- 讲师礼物 -->
      <div class="teacher_gift" v-else-if="navListActive === '2'">
        <div class="teacher_gift_list">
          <div class="giftTitle">
            普通礼物
          </div>
          <div class="gift_list">
            <div class="table_inner_body" :style="{top: tableTop + 'px'}">
              <div class="gift_list_item" v-for="(item,index) in ordinaryGiftList" :key="index">
                <span>{{ item.nick }}</span>送出<span>{{ item.name }}</span>x1
              </div>
            </div>
          </div>
        </div>
        <div class="teacher_gift_list">
          <div class="giftTitle">
            珍惜礼物
          </div>
          <div class="gift_list">
            <div class="table_inner_body" :style="{top: tableTop_2 + 'px'}">
              <div class="gift_list_item" v-for="(item,index) in sincerelyGiftList" :key="index">
                <span>{{ item.nick }}</span>送出<span>{{ item.name }}</span>x1
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetGiveGiftList } from '../api/home'
export default {
  name:'teacherIntroduce',
  data(){
    return{
      ordinaryGiftList: [],
      sincerelyGiftList: [],
      // 控制显示讲师简介
      control:false,
      teacher:{
        time:'16:09',
        visible:false,
        visibleWidth:'800px',
      },
      intoRoom:false,
      navListActive: '1',
      navList: [{
        name: '讲师简介',
        index: '1'
      }, {
        name: '讲师礼物',
        index: '2'
      }],
      tableList: [{
        name: '一直好运',
        giftName: '福气银币',
        number: '2'
      }],
      tableList_2: [{
        name: '一直好运',
        giftName: '福气银币',
        number: '2'
      }],
      tableTop: 0,
      tableTop_2: 0,
      tableListSize: 0,
      tableTimer: null,
      tableTimer_2: null,
      componentTimer: null,
      visibleSize: 6, //容器内可视最大完整行数
      lineHeight: 16, //每行的实际高度（包含margin-top/bottom,border等）
      componentTimerInterval: 3600000, //刷新数据的时间间隔
      tableTimerInterval: 100 //向上滚动 1px 所需要的时间，越小越快，推荐值 100
    }
  },
  computed:{
    // craig--newAdd
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    bnotes(){
      return this.$store.state.home.bnotes
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    packOrDevelop(){
      return this.$store.state.home.packOrDevelop
    },
  },
  created(){
    if(this.roomInfo.id == ''){
      this.intoRoom = true;
    }
  },
  mounted() {
    // clearInterval(this.componentTimer);
    // this.bsGetProductProcess()
    // this.componentTimerFun()
  },
  beforeDestroy() {
    // 离开页面销毁
    clearInterval(this.tableTimer);
    clearInterval(this.tableTimer_2);
    this.tableTop = 0;
    this.tableTop_2 = 0;
  },
  methods:{
    // 获取房间礼物列表事件
    geiRoomGiftList() {
      clearInterval(this.tableTimer);
      clearInterval(this.tableTimer_2);
      this.tableTop = 0;
      this.tableTop_2 = 0;
      let params = {
        tname: this.teacherInfo[1].whoami,
        size: 1000000,
        platid: 1
      }
      GetGiveGiftList(params).then(res => {
        if (res.Status === 0) {
          this.ordinaryGiftList = []
          this.sincerelyGiftList = []
          res.content.forEach(item => {
            if (item.gifttype === 1) {
              // 普通礼物
              this.ordinaryGiftList.push(item)
            } else if (item.gifttype === 2) {
              // 真心礼物
              this.sincerelyGiftList.push(item)
            }
          })
          this.tableActionFun('ordinaryGiftList', 'tableTimer', 'tableTop'),
          this.tableActionFun('sincerelyGiftList', 'tableTimer_2', 'tableTop_2')
        }
      })
    },
    // 积分榜tab点击事件
    navItemClick(item) {
      this.navListActive = item.index
      if (item.index === '2') {
        this.geiRoomGiftList()
      }
    },
    //调用数据接口，获取列表数据，根据自己情况填接口url
    bsGetProductProcess() {
      clearInterval(this.tableTimer);
      clearInterval(this.tableTimer_2);
      this.tableTop = 0;
      this.tableTop_2 = 0;
      this.tableActionFun();
    },
    tableActionFun(list, Timer, topType) {
      // this.tableListSize = this.tableList.length;
      if (this[list].length > this.visibleSize) {
        this[list] = this[list].concat(this[list]);
        this.tableTimerFun(list, Timer, topType);
      } else {
        // this.fillTableList(list, Timer, topType);
      }
    },
    tableTimerFun(list, Timer, topType) {
      var count = 0;
      this[Timer] = setInterval(() => {
        if (count < (this[list].length / 2) * this.lineHeight) {
          this[topType] -= 1;
          count++;
        } else {
          count = 0;
          this[topType] = 0;
        }
      }, this.tableTimerInterval);
    },
    //当数据过少时，不触发自动轮播事件，并填充没有数据的行，参数根据实际情况修改即可
    fillTableList(list, Timer, topType) {
      var addLength = this.visibleSize - this[list].length;
      for (var i = 0; i < addLength; i++) {
        this[list].push({
          name: '--',
          giftName: '--',
          number: '0'
        });
      }
    },
    componentTimerFun() {
      this.componentTimer = setInterval(() => {
        this.bsGetProductProcess();
      }, this.componentTimerInterval);
    },

    // craig--获取课堂笔记列表
    async getBnotes(){
      const option = {
        broomid:this.roomInfo.id,
        tid:this.teacherInfo && this.teacherInfo[1].id,
        bsheetid:this.teacherInfo && this.teacherInfo[0].value.id,
        sort:'ut,DESC',
      }
      await this.$store.dispatch('home/getBnotes',option)
    },
    // 控制讲师简介的显示
    controlClick(){
      this.control = !this.control;
      // 初始化课堂笔记
      this.getBnotes();
    },
    // 
    showDialog(){
      this.teacher.visible = true;
    },
    // 处理只显示十个字符，其他的用...代替
    getNewName(name) {
      var len = 0;
      var reallen = 0;
      for (var j=0; j<name.length; j++) {
        if (name.charCodeAt(j)>127 || name.charCodeAt(j)==94) {
          len += 1;
        } else {
          len ++;
        }
        if(len<11){
          reallen++;
        }
      }
      if (len > 10) {
        name = name.substr(0,reallen)+'···';
      }
      return name;
    },
  }
}
</script>

<style lang="less" scoped>
.navList {
  width: 200px;
  position:absolute;
  left: 333px;
  top: 5px;
  z-index: 4;
  display: flex;
  .navItem {
    font-size: 12px;
    color: #2FAEF8;
    width: 78px;
    height: 24px;
    min-height: 24px;
    text-align: center;
    background-color: #fff;
    writing-mode: unset;
    line-height: 24px;
    border-bottom-right-radius:5px ;
    border-top-right-radius:5px;
    margin-bottom: 0;
    letter-spacing: 4px;
    cursor: pointer;
    &:nth-child(1) {
      border-bottom-left-radius:5px ;
      border-top-left-radius:5px;
      border-bottom-right-radius:0 ;
      border-top-right-radius:0;
      height: 24px;
      border: 1px solid #158AEA;
      border-right: none;
    }
    &:nth-child(2) {
      border: 1px solid #158AEA;
      border-left: none;
    }
  }
  .navItem_active {
    background-color: #158AEA;
    color: #fff;
  }
}
.teacher_gift {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  .teacher_gift_list {
    &:nth-child(1) {
      margin-right: 19px;
    }
    .giftTitle {
      font-size: 14px;
      color: #333333;
      text-align: center;
      margin-bottom: 10px;
      &::before, &::after {
        content: '';
        width: 46px;
        height: 1px;
        border-bottom: 1px solid #333;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .gift_list {
      width: 194px;
      height: 108px;
      background: rgba(245, 245, 245, 0.5);
      overflow: hidden;
      position: relative;
      .table_inner_body {
        width: 174px;
        height: 98px;
        position: absolute;
        left: 0;
        padding: 5px 10px;
      }
      .gift_list_item {
        font-size: 12px;
        margin-bottom: 10px;
        span {
          &:nth-child(1) {
            margin-right: 3px;
          }
          &:nth-child(2) {
            color: #ef4c5c;
            margin: 0 3px;
          }
        }
      }
    }
  }
}
.big{
  width: 100%;
  height: 100%;
  background: url('../assets/images/teacherJs/banner.png') no-repeat;
  background-size: 100% 100%;
  .introduce{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .introduceBox{
      width: 390px;
      height: 150px;
      // border: 2px solid #eeeeee;
      border-radius: 5px;
      box-sizing: border-box;
      position: relative;
      .teaIntImg{
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      p{
        width: 100%;
        height: 100%;
        // overflow-y: auto;
        text-align: justify;
        white-space: normal;
        line-height: 22px;
        font-size: 14px;
        box-sizing: border-box;
        color: #13131b;
        padding: 25px 15px 0px;
      }
      P::-webkit-scrollbar{
        display: inline-block;
        width: 6px !important;
        height: 6px !important;
        background-color: #f1f1f1;
        background-clip: content-box;
        scroll-behavior: smooth;
      }
      p::-webkit-scrollbar-thumb{
        border-radius: 5px;
        width: 5px;
        background-color: #c3c3c3;
      }
    }
  }
}
.newBig{
  .introduce{
    .navList {
      left: 451px;
    }
    .introduceBox{
      // height: 100px;
    }
  }
}
</style>