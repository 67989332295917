<template>
  <div id="chat-list" ref="chat-list" class="scroll-item chat-list">
    <div id="chat-list-page" ref="chat-list-page" class="chat-list-page">
      <!-- 循环的时候分四种情况，别人普通发送，别人回复发送，自己普通发送，自己回复发送 -->
      <div
        v-for="item in roomHistoryChatRecordList"
        :key="item.id"
        class="chat-list-item"
      >
        <!-- 其他人发的消息（左边-普通） -->
        <div v-if="item.sid !== userInfo.id && item.type!==15 && item.type!==16 && item.type !== 1001" class="chat-item chat-item-left">
          <div class="chat-item-user">
            <!-- <span v-if="item.stype === 0">{{ String(item.sname).replace(String(item.sname).substring(2, String(item.sname).length - 2), '****') }}</span> -->
            <span v-if="item.stype === 0 || item.stype === 3">{{ item.sname }}</span>
            <span v-if="item.stype === 1" class="teacher-color">{{
              item.sname
            }}</span>
            <span v-if="item.stype === 2" class="sevicer-color">{{
              item.sname
            }}</span>
            <span>{{ handleTime(item.ut) }}</span>
          </div>
          <div class="chat-item-info" @mouseleave="handleOnMouseOut">
            <img class="headerimgLeft" :src="item.headerimg ? item.headerimg : primeImg" alt="">
            <div class="chat-info chat-reply-info" @mouseenter="handleOnMouseEnter(item)">
              <div v-if="item.param.CITE_TXT_ID" class="chat-item-reply">
                <div class="reply-leftline" />
                <div class="reply-user">{{ item.param.CITE_TXT_NAME }}</div>
                <div class="reply-info">
                  {{ item.param.CITE_TXT }}
                </div>
                <div class="reply-bottomline" />
              </div>
              <img class="deltaLeft" src="../assets/images/teacherJs/deltaLeft.png" alt="">
              <span v-if="item.rid" class="withotherpeople">@{{ item.rname }}</span>
              <span :class="item.stype === 0 ? '' : (item.stype === 1 ? 'newTeacherColor' : 'newSevicerColor')" class="textStyle" v-html="item.sname === '系统消息'?item.param.TXT:getHref(item.param.TXT)"></span>
            </div>
            <!-- craig -- 注销更多选项 -->
            <div v-if="showindex === item.id && userInfo.utype !== 0 && item.stype !== 3" class="more-handle">
              <img
                src="../assets/images/more-handle-icon.png"
                alt=""
                @click.stop="handleListClick(item)"
              >
              <ul id="handleLastPosition" v-if="handleId == item.id" :class="item.stype !== 0 ? 'handle-list handle-list-other' : 'handle-list'" ref="handleLastPosition">
                <li v-if="item.stype === 0" :class="colorClass===1 ? 'color-class' :''" @click.stop="handleEvent(1, item)" @mouseenter="handleLImouseEnter(1)" @mouseout="handleLImouseOut()">回复</li>
                <li v-if="item.stype === 0" :class="colorClass===2 ? 'color-class' :''" @click.stop="handleEvent(2, item)" @mouseenter="handleLImouseEnter(2)" @mouseout="handleLImouseOut()">禁言</li>
                <li v-if="item.stype === 0 && userInfo.utype !== 1" :class="colorClass===3 ? 'color-class' :''" @click.stop="handleEvent(3, item)" @mouseenter="handleLImouseEnter(3)" @mouseout="handleLImouseOut()">私聊</li>
                <li :class="colorClass===4 ? 'color-class' :''" @click.stop="handleEvent(4, item)" @mouseenter="handleLImouseEnter(4)" @mouseout="handleLImouseOut()">撤回</li>
                <li v-if="item.stype === 0" :class="colorClass===5 ? 'color-class' :''" @click.stop="handleEvent(5, item)" @mouseenter="handleLImouseEnter(5)" @mouseout="handleLImouseOut()">@</li>
              </ul>
            </div>
            <!-- craig -- 新增后台配置普通用户可以@后的页面展示 -->
            <div v-if="permissions && showindex === item.id" class="more-handle">
              <img
                src="../assets/images/more-handle-icon.png"
                alt=""
                @click.stop="handleListClick(item)"
              >
              <ul v-if="handleId == item.id" :class="item.stype !== 0 ? 'handle-list handle-list-other handle-permissions' : 'handle-list handle-permissions'" ref="handleLastPosition">
                <li :class="colorClass===5 ? 'color-class' :''" @click.stop="handleEvent(5, item)" @mouseenter="handleLImouseEnter(5)" @mouseout="handleLImouseOut()">@</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 自己发的消息(右边-普通) -->
        <div v-if="item.sid === userInfo.id && item.type!==15 && item.type!==16 && item.type !== 1001" class="chat-item chat-item-right">
          <div class="chat-item-user">
            <span v-if="item.stype === 0 || item.stype === 3">{{ item.sname }}</span>
            <span v-if="item.stype === 1" class="teacher-color">{{
              item.sname
            }}</span>
            <span v-if="item.stype === 2" class="sevicer-color">{{
              item.sname
            }}</span>
            <span>{{ handleTime(item.ut) }}</span>
          </div>
          <div class="chat-item-info" @mouseleave="handleOnMouseOut">
            <!-- craig--注销更多选项 -->
            <!-- <div v-if="showindex === item.id" class="more-handle"> -->
            <div v-if="showindex === item.id && userInfo.utype !== 0 && item.stype !== 3" class="more-handle">
              <img src="../assets/images/more-handle-icon.png" alt="" @click.stop="handleListClick(item)">
              <ul v-if="handleId === item.id" class="handle-list handle-myself">
                <li :class="colorClass===4 ? 'color-class' :''" @click.stop="handleEvent(4, item)" @mouseenter="handleLImouseEnter(4)" @mouseout="handleLImouseOut()">撤回</li>
              </ul>
            </div>
            <div class="chat-info chat-reply-info" @mouseenter="handleOnMouseEnter(item)">
              <div v-if="item.param.CITE_TXT_ID" class="chat-item-reply">
                <div class="reply-leftline" />
                <div class="reply-user">{{ item.param.CITE_TXT_NAME }}</div>
                <div class="reply-info">
                  {{ item.param.CITE_TXT }}
                </div>
                <div class="reply-bottomline" />
              </div>
              <img class="deltaRight" src="../assets/images/teacherJs/deltaRight.png" alt="">
              <span v-if="item.rid" class="withotherpeople">@{{ item.rname }}</span>
              <span :class="item.stype === 0 ? '' : (item.stype === 1 ? 'newTeacherColor' : 'newSevicerColor')" v-html="item.sname === '系统消息'?item.param.TXT:getHref(item.param.TXT)"></span>
            </div>
            <img class="headerimgRight" :src="item.headerimg ? item.headerimg : primeImg" alt="">
          </div>
        </div>
        <!-- 系统提示消息(中间) -->
        <div v-if="item.type == 1001" class="InfoCenter">
          <span>{{item.rname}}</span>
          <div v-html="item.param.TXT"></div>
        </div>
      </div>
      <div v-if="!roomHistoryChatRecordList.length" class="nodata">暂无数据</div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import config from '../config/index'
import { setTimeout } from 'timers'
export default {
  data() {
    return {
      handleType: 0,
      showindex: 0, // 鼠标悬浮事件发生将该值修改为当前悬浮元素的下标
      handleId: 0, // 右边操作列表显示的id
      colorClass: 0, // 操作中字体颜色修改
      // 获取聊天记录的参数
      roomOption:{
        broomid:'',
        sort: 'ut,DESC',
        stype:null,
        sid:null,
        platid:1,
        userid:null,
        size:null,
        page:0,
      },
      primeImg:require('../assets/images/craig/prime.png')
    }
  },
  computed: {
    roomHistoryChatRecordList() {
      return this.$store.state.home.roomHistoryChatRecordList
    },
    userInfo() {
      return this.$store.state.login.userInfo
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    roomset() {
      return this.$store.state.home.roomset
    },
    isatuoscroll() {
      return this.$store.state.home.isatuoscroll
    },
    // craig-newAdd
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    selectTree(){
      return this.$store.state.home.selectTree
    },
    displayNum(){
      return this.$store.state.home.displayNum
    },
    permissions(){
      let dicts ;
      this.$store.state.home.permissions.forEach(element => {
        if(element.label == 'ait_manage'){
          dicts = element.value;
        }
      });
      // 当后台配置了普通用户可以@别人dicts == 1 && 此用户为普通用户
      if(dicts == 1 && this.userInfo.utype == 0){
        return true
      }else{
        return false
      }
    },
    newRoomHistory(){
      return this.$store.state.home.newRoomHistory
    }
  },
  watch: {
    '$store.state.home.roomHistoryChatRecordList'() {
      // console.log(this.roomHistoryChatRecordList);
      const self = this
      if (this.isatuoscroll) {
        this.$refs['chat-list'].scrollTop = this.$refs['chat-list'].scrollHeight
        setTimeout(() => {
          self.$refs['chat-list'].scrollTop = self.$refs['chat-list'].scrollHeight
        }, 500)
      }
    },
    '$store.state.home.selectTree'(newVal){
      if(newVal == 1){
        this.comInit();
      }else if(newVal == 2){
        this.roomOption.sid = this.userInfo.id;
        this.roomOption.stype = 0;
        this.comInit();
      }else if(newVal == 3){
        this.roomOption.stype = 1;
        this.comInit();
      }
    }
  },
  created() {
    this.comInit();
  },
  methods: {
    async comInit() {
      // 判断当前有没有缓存用户信息和房间信息
      if (this.userInfo.id && this.roomInfo.id) {
      // 获取管理平台对房间的设置
        await this.$store.dispatch('home/getDicts', { name: 'talk_set', mark: config.platid })
        await this.$store.dispatch('home/getDisplayNum', { platid:1 })
        // const option = Object.assign({}, { broomid: this.roomInfo.id, scope: 2 }, { page: 0, size: this.roomset.pushnum, sort: 'ut,DESC' })
        // const data = Object.assign({
        //   broomid: this.roomInfo.id,
        //   sort: 'ut,DESC',
        //   stype:null,
        // })
        this.roomOption.broomid = this.roomInfo.id;
        this.roomOption.userid = this.userInfo.id;
        this.roomOption.size = this.displayNum;
        await this.$store.dispatch('home/getRoomHistoryChatRecord', this.roomOption);
        this.roomOption.stype = null;
        this.roomOption.sid = null;
      }
    },
    // 鼠标悬浮事件
    handleOnMouseEnter(item) {
      this.showindex = item.id
      this.handleId = 0
    },
    // 鼠标离开消息区域
    handleOnMouseOut() {
      this.showindex = 0
      this.handleId = 0
    },
    handleLImouseEnter(num) {
      this.colorClass = num
    },
    handleLImouseOut() {
      this.colorClass = 0
    },
    // 点击更多操作图标
    handleListClick(item) {
      this.$store.dispatch('home/getNewRoomHistory',this.roomOption)
      this.showindex = item.id
      this.handleId = item.id
      // craig -- 解决最后一个消息点开更多选项时下半部分被遮挡的问题
      if(this.newRoomHistory[0]){
        if(this.newRoomHistory[0].id == item.id){
          if(item.stype !== 0){
            this.$nextTick(function(){
              let dom = document.getElementById('handleLastPosition');
              dom.classList.add('handleLastPosition');
            })
          }else{
            this.$nextTick(function(){
              let dom = document.getElementById('handleLastPosition');
              dom.classList.add('handleLastUser');
            })
          }
        }
      }
    },
    // 更多操作事件（事件处理在home组件中，此处只是触发事件）
    handleEvent(type, item) {
      this.showindex = 0
      this.handleId = 0
      // type:1、回复引用 2、禁言 3、私聊 4、撤回发言 5、@别人
      this.$emit('handleEvent', type, item)
    },
    // 时间处理
    handleTime(time) {
      if (new Date(Number(time)).toDateString() === new Date().toDateString()) {
        return moment(time).format('HH:mm:ss')
      } else if (new Date(Number(time)) < new Date()){
        return moment(time).format('MM-DD HH:mm:ss')
      }
    },
    // 提取字符串中的链接，并替换a标签
    getHref(content) {
      if (!content) {
        return '';
      }
      const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g;
      content = content.replace(urlPattern, (match) => {
        const urlHttp = match.indexOf('http://') && match.indexOf('https://');
        const url = urlHttp === -1 ? match.split('/')[0] : match.split('/')[2];
        const href = urlHttp === -1 ? `https://${match}` : match;
        return `<a target="_blank" href="${href}" style="color: #3298FF">${href}</a>`;
      });
      return content;
    },
  }
}
</script>

<style lang="less" scoped>
.chat-list {
  height: 100%;
  padding: 5px 20px 20px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.scroll-item::-webkit-scrollbar{
  width: 2px;
}
.scroll-item::-webkit-scrollbar-thumb {
  background-color: #fb5c76;
  border-radius: 2em;
}
.scroll-item::-webkit-scrollbar-track {
  background-color: #b6bfc2;
  border-radius: 2em;
}
.chat-list-page {
  .chat-list-item {
    &:after {
      display: block;
      clear: both;
      content: "";
      visibility: hidden;
      height: 0;
    }
    .chat-item {
      margin-bottom: 5px;
      .chat-item-info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        .chat-info {
          // max-width: 230px;
          padding: 10px;
          border-radius: 4px;
          background: #eff3f5;
          font-size: 14px;
          color: #000;
          border: 1px solid #eeeeee;
          .withotherpeople {
            color: #3abdff;
            margin-right: 5px;
          }
          // craig新增老师和客服字体颜色
          .newTeacherColor{
            color: #ac5d26;
          }
          .newSevicerColor{
            color: #e90014;
            ::v-deep img {
              width: 100%;
            }
          }
          .chat-item-reply {
            position: relative;
            padding: 5px;
            color: #8c99a5;
            .reply-info {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .reply-leftline {
              position: absolute;
              top: 0;
              left: 0;
              width: 1px;
              height: 28px;
              background: #ebdfcf;
            }
            .reply-bottomline {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: #ebdfcf;
            }
          }
        }
        .chat-reply-info {
          max-width: 60%;
          background: #fff;
          position: relative;
          word-break:break-all;
          .deltaLeft{
            position: absolute;
            top: 10px;
            left: -10px;
            z-index: 2;
          }
          .deltaRight{
            position: absolute;
            top: 10px;
            right: -10px;
            z-index: 2;
          }
          .textStyle {
            display: inline-block;
            ::v-deep img {
              width: 100%;
            }
          }
        }
        .headerimgRight{
          width: 40px;
          height: 40px;
          margin-left: 10px;
          border-radius: 50%;
        }
        .headerimgLeft{
          width: 40px;
          height: 40px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .more-handle {
          position: relative;
          width: 28px;
          height: 24px;
          margin: 0 6px;
          cursor: pointer;
          img {
            width: 28px;
            height: 24px;
          }
          .handle-list {
            position: absolute;
            top: -20px;
            width: 70px;
            // min-height: 100px;
            background: #ffffff;
            z-index: 9;
            font-size: 12px;
            color: #65686f;
            cursor: pointer;
            padding: 5px;
            box-shadow: 3px 3px 3px #e7eaeb, 3px -3px 3px #e7eaeb,
              -3px 3px 3px #e7eaeb, -3px -3px 3px #e7eaeb;
            li {
              cursor: pointer;
              padding: 0 5px;
              background: #ffffff;
              line-height: 22px;
              text-align: center;
            }
            .color-class {
              color: #3388ff;
            }
          }
          .handle-list-other {
            height: 32px;
          }
          .handle-permissions{
            height: 25px;
            top: -10px;
          }
          .handle-myself{
            top: -10px;
          }
          .handleLastPosition{
            top: -10px;
          }
          .handleLastUser{
            top: -98px;
          }
        }
      }
    }
    .chat-item-left {
      float: left;
      clear: both;
      .chat-item-user {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        font-size: 12px;
        color: #aeaeae;
        margin-bottom: 4px;
        span {
          padding-right: 5px;
        }
        span:nth-child(1){
          color: #6f6f6f;
        }
      }
      .handle-list {
        left: 33px;
      }
    }
    .chat-item-right {
      float: right;
      clear: both;
      .chat-item-user {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        font-size: 12px;
        color: #aeaeae;
        margin-bottom: 4px;
        span {
          padding-left: 5px;
        }
        span:nth-child(1){
          color: #6f6f6f;
        }
      }
      .chat-item-info {
        justify-content: flex-end;
        .more-handle {
          .handle-list {
            right: 33px;
            height: 32px;
          }
        }
      }
    }
    .InfoCenter{
      text-align: center;
      font-size: 14px;
      box-sizing: border-box;
      padding: 5px 0;
      color: #656565;
      span{
        color: #686eb8;
      }
    }
    /**不同的人名字颜色不同**/
    .my-color {
      color: #fe3f2c;
    }
    .teacher-color {
      // color: #3289fd;
    }
    .sevicer-color {
      // color: #ff9251;
    }
  }
  .nodata {
    font-size: 14px;
    color: #8c99a5;
    padding: 10px;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .chat-list {
    height: 250px;
  }
  .chat-list-page .chat-list-item .chat-item .chat-item-info .chat-reply-info{
    background: #f7f8fa;
    span{
      font-size: 12px;
    }
  }
}
</style>
