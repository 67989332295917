<template>
  <div class="login-item">
    <div slot="title" class="login-header">红狮课堂</div>
    <template>
      <el-tabs v-model="controlActiveName" @tab-click="handleClick">
        <el-tab-pane label="登录" name="second">
          <el-form
            ref="loginForm"
            :model="reqData"
            class="login-form"
            label-position="left"
          >
            <el-form-item prop="phone" class="login-form-item">
              <el-input
                v-model="reqData.username"
                prefix-icon="el-icon-mobile-phone"
                placeholder="请输入手机号或账号"
              />
            </el-form-item>
            <el-form-item prop="password" class="login-form-item">
              <el-input
                v-model="reqData.password"
                prefix-icon="el-icon-lock"
                show-password
                type="password"
                placeholder="请输入账号密码"
                @keyup.enter.native="handleSubmit"
              />
            </el-form-item>
            <p class="forget_p clearfix">
              <el-checkbox style="opacity:0" v-model="reqData.checked">记住密码</el-checkbox>
              <!-- <span class="forget_mm" @click.stop="handleForget">忘记密码?</span> -->
            </p>
            <el-form-item label-width="0">
              <div class="login-form-btn" @click.stop="handleSubmit">登录</div>
            </el-form-item>
            <span style="display:block; width:82%;margin:0 auto">我们会严格保护您的数据安全</span>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="注册" name="first">
          <!-- <el-form
            ref="loginForm2"
            :model="reqData2"
            :rules="loginForm2Rules"
            class="login-form"
            label-position="left"
          >
            <el-form-item prop="phone" class="login-form-item">
              <el-input
                v-model="reqData2.phone"
                prefix-icon="el-icon-mobile-phone"
                placeholder="请输入手机号或账号"
              />
            </el-form-item>
            <el-form-item prop="password" class="login-form-item">
              <el-input
                v-model="reqData2.password"
                prefix-icon="el-icon-connection"
                placeholder="请输入验证码"
              />
              <el-button type="text" :disabled="smsDisabled" class="btn_sms" @click="_getSms">{{ smsBtnText }}</el-button>
            </el-form-item>
            <el-form-item prop="password" class="login-form-item">
              <el-input
                v-model="reqData2.code"
                prefix-icon="el-icon-lock"
                show-password
                type="password"
                placeholder="请输入账号密码"
                @keyup.enter.native="handleSubmit"
              />
            </el-form-item>
            <el-form-item label-width="0">
              <div class="login-form-btn" @click.stop="handleSubmit">开立真实账户</div>
            </el-form-item>
          </el-form> -->
          <register></register>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>
<script>
import { get } from 'http'
import register from './register.vue'
export default {
  components:{
    register,
  },
  data() {
    return {
      activeName: 'second',
      smsTime: 30,
      reqData2: {
        username: '',
        password: '',
        device: 'pc',
        signtype: 10,
        code:'',
        plats:[{"id":1}],
      },
      reqData: {
        username: '',
        password: '',
        device: 'pc',
        signtype: 10,
        code:'',
        conner:true,
        plats:[{"id":1}],
        nick:'',
      },
      smsDisabled: false,
      smsBtnText: '获取验证码',
      loginForm2Rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        password: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      },
      loginFormRules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        password: [
          { required: true, message: '登录密码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    dialogObj() {
      return this.$store.state.home.dialogObj
    },
    controlActiveName(){
      return this.$store.state.login.activeName
    }
  },

  watch: {
    'dialogObj.dialogVisible'(newVal) {
      if (!newVal) {
        this.$refs['loginForm'].resetFields()
        // this.$refs['loginForm2'].resetFields()
      }
    },
    'controlActiveName'(newVal){
      if(newVal){
        this.activeName = this.controlActiveName;
        console.log(this.activeName);
      }
    }
  },
  // mounted(){
  //   console.log(this.controlActiveName);
  // },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
      console.log(1)
    },
    async _getSms() {
      const params = {
        phone: this.reqData2.phone
      }
      if (params.phone !== '') {
        const res = await this.$store.dispatch('login/handleSms', params)
        console.log(res)
        setInterval(() => {
          this.smsBtnText = this.smsTime + '秒后重新获取'
          this.smsTime--
          if (this.smsTime < 0) {
            this.smsBtnText = '获取验证码'
            this.smsDisabled = false
            clearInterval()
          }
        }, 1000)
      }
    },
    // 登录
    handleSubmit() {
      console.log(this.activeName)
      this.$refs['loginForm'].validate(async valid => {
        if (valid) {
          this.reqData.nick = String(this.reqData.username).replace(String(this.reqData.username).substring(2, String(this.reqData.username).length - 2), '****');
          await this.$store.dispatch('login/handleLogin', this.reqData)
          if (this.$store.state.login.userInfo.id) {
            this.$refs['loginForm'].resetFields()
            this.$store.commit('home/set_dialogobj', { dialogVisible: false })
            // 登录成功获取房间列表
            this.$emit('loginSuccess')
          }
        }
      })
      //  else {
      //   this.$refs['loginForm2'].validate(async valid => {
      //     if (valid) {
      //       await this.$store.dispatch('login/handleLogin', this.reqData2)
      //       if (this.$store.state.login.userInfo.id) {
      //         this.$refs['loginForm2'].resetFields()
      //         this.$store.commit('home/set_dialogobj', { dialogVisible: false })
      //         // 登录成功获取房间列表
      //         this.$emit('loginSuccess')
      //       }
      //     }
      //   })
      // }
    },
    // 点击忘记密码
    handleForget() {
      this.$store.commit('home/set_dialogobj', { dialogVisible: false })
      this.$store.commit('home/set_dialogobj3', {
        dialogVisible: true,
        isCloseIcon: true,
        type: 1,
        width: '500px'
      })
    }
  }
}
</script>

<style lang="less">
.loginBox {
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog{
     border-radius: 8px;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.login-item {
  .login-header {
    font-size: 22px;
    color: #fff;
    line-height: 80px;
    height: 80px;
    background: #2c2c2c;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 50px;
    border-radius: 0px;
  }
  .el-tabs__item.is-active{
    color:#111111;
    font-weight: 550;
  }
  .el-tabs__active-bar{
    background-color: #f22118;
  }
  .el-tabs__item{
    color:#656565;
  }
  .el-tabs__nav-scroll{
    padding-left: 50px;
  }
  .login-form {
    margin-top: 30px;
    .login-form-item {
      width: 81%;
      margin: 20px auto;
      padding: 0 10px;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      border: 1px solid #c0c4cc;
      .el-form-item__label {
        text-align: center !important;
        color: #394d62;
        font-size: 14px;
      }
      input {
        flex: 1;
        font-size: 14px;
        color: #394d62;
        outline: none;
        border: none;
      }
    }
    .btn_sms{
      position: absolute;
      right: 0;
    }
    .el-button--text{
      color:#111111;
    }
    .login-form-btn {
      width: 85%;
      margin: 0  auto;
      // background: linear-gradient(90deg, #ed000a, #fe6c39);
      background-color: #ee2547;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      border-radius: 8px;
      cursor: pointer;
      height: 50px;
    }
    .clearfix {
      *zoom: 1;
    }

    // .clearfix:before,
    // .clearfix:after {
    //   display: table;
    //   line-height: 0;
    //   content: "";
    // }

    .clearfix:after {
      clear: both;
    }
    .forget_p{
      width: 85%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      zoom: 1;
      margin-bottom: 20px;
      .forget_mm{
        cursor: pointer;
        color: #35a2ff;
      }
    }

  }
}
@media screen and (max-width: 500px) {
  .login-item{
    .login-header{
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 600;
      padding-left: 20px;
    }
    .login-form{
      margin-top: 10px;
      .login-form-item{
        margin: 15px auto;
      }
      .forget_p{
        margin-bottom: 10px;
      }
      .login-form-btn{
        height: 42px;
        line-height: 42px;
        font-size: 15px;
      }
      .goLogin{
        margin: 0 auto 10px;
      }
    }
  }
  .el-tabs__header{
    margin: 0 auto 10px;
  }
  .el-form-item{
    margin-bottom: 10px;
  }
}
</style>
