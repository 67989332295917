<template>
  <div class="scroll-item user-list-item">
    <div class="user-list-header">
      <!-- ({{ roomUserTotal }}) -->
      当前房间客户
      <span class="user-list-close" @click.stop="$emit('closeUser')"></span>
    </div>
    <div class="user-item-section scroll-item">
      <div v-for="item in customList" :key="item.id" class="user-item">
        <!-- <div class="user-item-type">
          <span v-if="item.utype === 1">讲师</span>
          <span v-else-if="item.utype === 2">客服</span>
          <span v-else>会员</span>
        </div> -->
        <div
          class="user-item-avatar"
        >
          <img
            @error="imgLoadError"
            class="showImg"
            :src="item.avatar ? item.avatar : defaultAvatar"
            alt=""
          />
        </div>
        <div class="user-item-name">
          {{ item.unick }} <br />
          <span style="color: #279CF7;">{{ item.mtaccr }}</span>
        </div>
        <div
          class="item-private-btn"
          @click.stop="handlePrivateEvent(item)"
        >
          聊天
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultAvatar:
        "https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093710877.png?Expires=3575237832&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=%2BRjt%2BVRwRICB4Kfiu4GG7OpodbE%3D",
      customList: [],
    };
  },
  computed: {
    currentRoomUserList() {
      return this.$store.state.home.currentRoomUserList;
    },
    privateUserList() {
      return this.$store.state.home.privateUserList;
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    },
    roomInfo() {
      return this.$store.state.home.roomInfo;
    },
    roomUserTotal() {
      return this.$store.state.home.roomUserTotal;
    },
  },
  created() {
    this.comInit();
  },
  methods: {
    // 头像加载失败事件
    imgLoadError(e) {
      let img = e.srcElement;
      img.src =
        "https://haodooss.oss-accelerate.aliyuncs.com/pem/images/cardinfo/20230503093710877.png?Expires=3575237832&OSSAccessKeyId=LTAI5tPmA5wBPs6DVUUtaZv5&Signature=%2BRjt%2BVRwRICB4Kfiu4GG7OpodbE%3D";
      img.onerror = null; //防止闪图
    },
    async comInit() {
      if (this.userInfo.id && this.roomInfo.id) {
        // 获取当前房间的在线用户
        await this.$store.dispatch("home/getCurrentRoomUser", {
          broomid: this.roomInfo.id,
        });

        // 筛选当房间在线普通用户
        this.currentRoomUserList.forEach((item) => {
          if (item.utype !== 1 && item.utype !== 2) {
            this.customList.push(item);
          }
        });
      }
    },
    // 点击私聊
    /* async handlePrivateEvent(info) {
      this.$store.commit("home/set_ishomePrivateBtn", false);
      // 请求当前客服或者老师的私聊用户列表
      const option = {
        id: this.userInfo.id, // 当前登录老师或者客服id
        broomid: this.roomInfo.id, // 当前所在房间id
      };
      await this.$store.dispatch("home/getUserListByManagerId", option);
      const list = Object.assign([], this.privateUserList);
      if (list.length) {
        // 用户列表不为空，要去重，先检查列表中有没有该用户，如果有就先移除，然后把当前用户添加进去；如果没有直接添加进去
        const regList = [];
        list.forEach((item) => {
          regList.push(item.id);
        });
        if (regList.indexOf(info.userid) > -1) {
          list.splice(regList.indexOf(info.userid), 1);
        }
        list.unshift({ id: info.userid, nick: info.unick });
      } else {
        list.push({ id: info.userid, nick: info.unick });
      }
      console.log(list)
      // 设置私聊用户列表
      this.$store.commit("home/set_privateUserList", list);
      // 设置状态机当前私聊用户信息
      this.$store.commit("home/set_privateUserInfo", {
        id: info.userid,
        nick: info.unick,
      });
      // 请求和选中私聊用户的聊天记录
      const params = {
        broomid: this.roomInfo.id,
        type: 11,
        id1: this.userInfo.id,
        id2: info.userid,
      };
      await this.$store.dispatch(
        "home/getUserPrivateChatHistoryListById",
        params
      );
      // 打开私聊窗口
      this.$store.commit("home/set_dialogobj", {
        dialogVisible: true,
        isCloseIcon: false,
        type: 3,
        width: "650px",
      });
      // 触发关闭用户列表
      this.$emit("closeUser");
    }, */
    handlePrivateEvent(item) {
      // 打开私聊窗口
      this.$emit("openChat", item);
      // 触发关闭用户列表
      this.$emit("closeUser");
    }
  },
};
</script>
<style lang="less" scoped>
.user-list-item {
  background: #ffffff;
  width: 346px;
  box-shadow: 10px 4px 15px #e6e9ea;
  .user-list-header {
    height: 50px;
    line-height: 50px;
    padding-left: 24px;
    font-size: 16px;
    color: #ffffff;
    background: #090f38;
    .user-list-close {
      width: 38px;
      height: 50px;
      float: right;
      background: url("../assets/images/user-list-close.png") no-repeat center;
      background-size: 12px 12px;
      cursor: pointer;
    }
  }
  .user-item-section {
    padding: 10px 24px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 590px;
  }
  .scroll-item::-webkit-scrollbar {
    width: 2px;
  }
  .scroll-item::-webkit-scrollbar-thumb {
    background-color: #8c8ea7;
    border-radius: 2em;
  }
  .scroll-item::-webkit-scrollbar-track {
    background-color: #b6bfc2;
    border-radius: 2em;
  }
  .user-item {
    overflow: hidden;
    padding: 12px 0;
    border-bottom: 1px solid #EAECF0;

    .user-item-type {
      padding: 0 8px;
      border-radius: 9px;
      font-size: 12px;
      color: #ffffff;
      background: #ddba69;
    }
    .user-item-avatar {
      width: 35px;
      height: 35px;
      overflow: hidden;
      float: left;
      border: 1px solid #c5c8c9;
      border-radius: 50%;
      .showImg {
        width: 100%;
        height: 100%;
      }
    }
    .user-item-name {
      line-height: 18px;
      max-width: 140px;
      font-size: 14px;
      color: #394d62;
      margin-left: 5px;
      overflow: hidden;
      float: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-private-btn {
      width: 85px;
      height: 25px;
      line-height: 25px;
      margin: 6px 0;
      border-radius: 4px;
      background: #e81f1f;
      text-align: center;
      color: #ffffff;
      font-size: 12px;
      cursor: pointer;
      float: right;
    }
  }
}
</style>
