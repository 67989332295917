<template>
  <div class="scroll-item room-list-item">
    <!-- <div class="room-list-header">房间列表</div> -->
    <div class="room-item-section">
      <div v-for="item in roomList" :key="item.id" class="room-item" :class="roomInfo.id !== item.id ? 'noCurrentRoom' : 'isCurrentRoom'">
        <div class="roomBox">
          <div class="titLeftBox">
            <img v-if="item.pwd" class="vipImg" align="left" src="../assets/images/craig/vip.png" alt="">
            <span class="room-name">{{ item.name }}</span>
          </div>
          <!-- 新增报名时间，课程时间 -->
          <div class="roomTime" v-if="item.bclass">
            <p>报名时间:{{ timestampToTime(item.bclass.aptime) }}-{{ timestampToTime(item.bclass.apetime) }}</p>
            <p>课程时间:{{ timestampToTime(item.bclass.stime) }}-{{ timestampToTime(item.bclass.etime) }}</p>
          </div>
          <div class="roomButton">
            <button class="sign-up" @click.stop="handleSignUp(item)" v-if="item.bclass" :disabled="item.regStatus !== '1'?true:false">
              {{ item.regStatus === '1'? '立即报名' : item.regStatus === '2'? '已过期' : item.regStatus === '3' ? '已报名' : '未开始'  }}
            </button>
            <button class="goin-home" v-if="roomInfo.id !== item.id" @click.stop="handleEnterRoom(item)">
              进入房间
            </button>
            <span v-if="roomInfo.id === item.id" class="current-room">
              <label for=""></label>
            当前房间
            </span>
          </div>
        </div>
        <div class="room-info">
          <span v-if="item.isplay" class="room-living">
            <!-- <img src="../assets/images/voice-icon.png" alt=""> -->
            直播中
          </span>
          <span v-else class="room-living" style="color:#f8fdff">
            <!-- <img src="../assets/images/voice-icon.png" alt=""> -->
            无直播
          </span>
        </div>
        <img
          v-if="roomInfo.id === item.id"
          class="room-item-img"
          :src="item.backimg ? item.backimg : roomImg"
          alt=""
        >
      </div>
    </div>
    <!-- 提示老师结束上播状态弹窗 -->
    <el-dialog title="提示" :visible.sync="onPlayObj.show" center width="400px" @close="handleCancelEvent">
      <p>
        您在<span class="onplay-room-name">{{ roomInfo.name }}</span>处于上播状态。您确定要下播吗？
      </p>
      <span slot="footer" class="dialog-footer">
        <button
          class="dialog-btn-common dialog-btn-cancel"
          @click.stop="handleCancelEvent"
        >
          取 消
        </button>
        <button
          class="dialog-btn-common dialog-btn-sure"
          type="primary"
          @click.stop="handleRoomChange"
        >
          确 定
        </button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import config from '../config/index'
import { setSessionStorage, getIMEI } from '../utils/tool'
import timeTable from './timeTable.vue'
import { roomSignUp } from '../api/home'
export default {
  name:'roomList',
  components:{
    timeTable,
  },
  data() {
    return {
      roomImg: require('../assets/images/room-img.png'),
      onPlayObj: {
        show: false
      },
      youkeData: {
        plats: [
          {
            id: '1'
          }
        ],
        signtype: '10',
        device: 'pc',
        nick: '游客',
        username: this.getCookie('UUID') || getIMEI(),
        utype:0,
      },
      conner:true,
      roomMsg: {},
      pagesInfo: {
        page: 0,
        size: 10
      },
      // 保存进入的房间
      craigRoom:'',
    }
  },
  computed: {
    showRoomList() {
      return this.$store.state.home.showRoomList
    },
    roomList() {
      const list = this.$store.state.home.roomList;
      if(this.roomInfo.id){
        list.forEach((element,i) => {
          if(element.id == this.roomInfo.id){
            this.craigRoom = element;
            list.splice(i,1);
          }
          return
        });
        list.unshift(this.craigRoom);
      }
      return list
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    userInfo() {
      return this.$store.state.login.userInfo
    },
    isplay() {
      return this.$store.state.home.isplay
    },
    defaultVideoInfo() {
      return this.$store.state.home.defaultVideoInfo
    },
    roomset() {
      return this.$store.state.home.roomset
    },
    socket() {
      return this.$store.state.home.socket
    },
    roomtid() {
      return this.$store.state.home.roomtid
    },
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
    player() {
      return this.$store.state.home.player
    },
    // craig-newAdd
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    craigPlay(){
      return this.$store.state.home.craigPlay
    },
    livingInfo(){
      return this.$store.state.home.livingInfo
    },
    pwdRight(){
      return this.$store.state.home.pwdRight
    },
    vipRoom(){
      return this.$store.state.home.vipRoom
    },
    isComming(){
      return this.$store.state.home.isComming
    }
  },
  watch:{
    '$store.state.home.craigPlay'(newVal){
      if(newVal){
        // craig--获取正在直播的房间列表
        this.getLiving();
      }
    },
    'livingInfo'(newVal){
      if(newVal){
        if(!sessionStorage.getItem('livingKey') || !sessionStorage.getItem('userInfo')){
          this.handleEnterRoom(newVal);
          sessionStorage.setItem('livingKey',true);
        }
      }
    },
    'roomList'(){
      if(this.roomList.length == 1 && !sessionStorage.getItem('onlyOnce')){
        this.handleEnterRoom(this.roomList[0]);
        sessionStorage.setItem('onlyOnce',true);
      }
    },
    'pwdRight'(newVal){
      if(newVal){
        this.intoRoom(this.vipRoom);
      }
    }
  },
  methods: {
    /**
     * 进入房间事件处理（两种情况）
     * 1、当前用户是讲师，并且处于上播状态
     * 2、当前用户是讲师但不在上播状态 || 当前用户是客服
     **/
    getCookie(name) {
      const strCookie = document.cookie
      const arrCookie = strCookie.split(';')
      for (let i = 0; i < arrCookie.length; i++) {
        const arr = arrCookie[i].split('=')
        if (arr[0].replace(/(^\s*)|(\s*$)/g, '') === name) {
          return arr[1]
        }
      }
      return ''
    },
    async handleSignUp(item) {
      // 未登录情况
      if (item.param.Acc_Type !== 'tourist' && !this.userInfo.id) {
        this.$store.commit('home/set_dialogobj', {
          dialogVisible: true,
          isCloseIcon: false,
          type: 1,
          width: '500px'
        })
        return
      }
      // 报名接口
      const res = await roomSignUp({id: item.bclass.id})
      // 报名成功
      if (res.Status === 0) {
        this.$alert('恭喜您，报名成功！按时来听课吆~', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        this.$alert(`很抱歉，您不满足本次报名条件：${res.message}`, '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    },
    async handleEnterRoom(item) {
      console.log('进入房间的信息', item)
      // 没有登录直接提示用户登录
      if (item.param.Acc_Type !== 'tourist' && !this.userInfo.id) {
        this.$message({
          type: 'error',
          message: '请您登录后再进入房间'
        })
        return
      }
      if (!this.userInfo.id) {
        console.log('我是游客');
        this.youkeData.nick = '游客' + this.youkeData.username.substring(this.youkeData.username.length - 4, this.youkeData.username.length)
        await this.$store.dispatch('home/youkeLogin', this.youkeData)
        this.$store.commit('home/set_dialogobj', { dialogVisible: false })
        // 登录成功获取房间列表
        this.$emit('loginSuccess')
        this.intoRoom(item)
      }
      // 判断用户是否满足进入房间的要求（首入金、净入金等）
      const isData = {
        'broomid':item.id,
        'userid':this.userInfo.id,
      }
      await this.$store.dispatch('home/Iswelcome',isData)
      setTimeout(() => {
        if(this.isComming){
          // 判断是否为vip房间
          if(item.pwd){
            this.$store.commit('home/set_isVip',true);
            this.$store.commit('home/set_vipRoom',item);
            return
          }else{
            // 非vip房间直接进入
            this.intoRoom(item)
          }
        }else{
          return
        }
      }, 800);
    },
    // 进入房间的逻辑
    async intoRoom(item){
      if (this.userInfo.utype === 1 && this.isplay && this.userInfo.id === this.roomtid) {
        console.log('我是讲师上播了');
      // 如果当前用户是讲师，但处于上播状态需要提示
        this.onPlayObj.show = true
        this.roomMsg = item
      } else {
        console.log('我是讲师没有上播');
        this.isplay ? this.socket.close() : '' // 如果没有在上播状态不需要断开socket
        this.$store.commit('home/set_roominfo', item)
        this.$store.commit('home/set_roomtid', item.tId)
        setSessionStorage('roomInfo', item)
        await this.$store.dispatch('home/getCurrentRoomUser', {
          broomid: this.roomInfo.id
        })

        // 连接socket
        // craig--新增链接逻辑，去掉游客的userid，如果没有登录，重新分发新的userid
        if(!this.userInfo.id){
          if(sessionStorage.getItem('craigNewUserid')){
            const params = {
              roomid: this.roomInfo.id,
              uid: sessionStorage.getItem('craigNewUserid')
            }
            await this.$store.dispatch('home/socketInit', params)
          }else{
            const params = {
              roomid: this.roomInfo.id,
              uid: this.craigNewUserid()
            }
            sessionStorage.setItem('craigNewUserid',this.craigNewUserid())
            await this.$store.dispatch('home/socketInit', params)
          }
        }
        // 获取当前房间的聊天记录
        await this.getRoomHistory()
        // 获取当前房间的在线用户
        await this.$store.dispatch('home/getCurrentRoomUser', {
          broomid: this.roomInfo.id
        })

        // 获取选中房间的视频流地址或者默认视频地址 设置视频流地址或者默认播放视频 isplay: true 直播中（设置视频流地址）  false 不在直播（设置默认视频地址）
        if (this.roomInfo.isplay) {
          console.log('正在直播中');
          console.log(item);
          this.$store.commit('home/set_ckplayerObject', { video: item.param.pcurl || item.isplay, live: true })
          // eslint-disable-next-line no-undef
          this.$store.commit('home/set_player', new Ckplayer(this.ckplayerObject))
          // vue-video-player插件，暂时废弃该方法
          // this.$store.commit('home/set_videoPlayerConfig', { sources: [{ src: item.param.pcurl, type: 'application/x-mpegURL' }] })
        } else {
          console.log('没有直播，播放默认视频');
          const TheTime = new Date().getTime()
          if (((TheTime - item.param.stime1) > 0 && (TheTime - item.param.etime1) < 0) || ((TheTime - item.param.stime2) > 0 && (TheTime - item.param.etime2) < 0)) {
            // 获取默认视频信息(测试环境发现有些房间的默认播放视频的id为空，所以加了判断)
            item.param.efile_id ? await this.$store.dispatch('home/getRoomDefaultVideoInfo', { id: item.param.efile_id, type: 0 }) : ''
          }
        }
        // 获取管理平台对房间的设置
        await this.$store.dispatch('home/getDicts', { name: 'talk_set', mark: config.platid })
        // 最后关闭弹出层
        this.$store.commit('home/set_showroomlist', false)
        this.$store.commit('home/set_isplay', this.roomInfo.isplay)
      }
      location.reload();
    },
    // 获取反房间历史聊天记录
    async getRoomHistory() {
      const option = Object.assign({}, { broomid: this.roomInfo.id, scope: 2 }, this.pagesInfo, { size: this.roomset.pushnum, sort: 'ut,DESC' })
      const data = Object.assign({
        broomid: this.roomInfo.id,
        sort: 'ut,DESC',
        // 课程id
        // bsheetid:this.teacherInfo[0].value.id,
        stype:null,
        platid:1,
        userid:this.userInfo.id
      })
      await this.$store.dispatch('home/getRoomHistoryChatRecord', data)
    },
    // 确定进入其他房间
    async handleRoomChange() {
      this.$store.commit('home/set_isplay', false)
      if (!this.isplay) {
        const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        // 讲师下播需要给socket发消息，通知客户端（APP和PC端）将房间的视频播放地址改为默认视频播放地址(在socket中修改地址)
        const option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 16,
          param: {
            TXT: '老师直播结束了'
          }
        }
        this.socket.send(JSON.stringify(option))
        this.onPlayObj.show = false
        await this.handleEnterRoom(this.roomMsg)
        const params = {
          platid: config.platid,
          page: 0,
          size: 10
        }
        await this.$store.dispatch('home/getRoomList', params)
      }
    },
    handleCancelEvent() {
      this.onPlayObj.show = false
      this.$store.commit('home/set_showroomlist', false)
    },
    // craig--获取正在直播的房间列表
    async getLiving(){
      await this.$store.dispatch('home/getLiving',{platid:config.platid})
    },
    // 随机生成userid
    craigNewUserid(){
      let guid = ''
      for (let i = 1; i <= 32; i++) {
        const n = Math.floor(Math.random() * 16.0).toString(16)
        guid += n
      }
      return guid;
    },
    timestampToTime(timestamp) {
      let date = new Date(parseInt(timestamp));
      let Year = date.getFullYear();
      let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      return Year + '-' + Moth + '-' + Day
    }
  },
}
</script>
<style lang="less" scoped>
.room-list-item {
  background: #F1F9FF;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 650px;
  max-height: 690px;
  width: 290px;
  min-width: 290px;
  .room-list-header {
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color: #333333;
    // border-bottom: 1px solid #dededd;
    background-color: #e6edf3;
  }
  .room-item-section {
    .room-item {
      height: auto;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 15px;
      .room-item-img {
        width: 100%;
        height: 150px;
      }
      .roomBox{
        position: relative;
        z-index: 3;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin: 15px auto 2px;
        .titLeftBox{
          .vipImg{
            width: 30px;
            vertical-align: middle;
            margin-top: -5px;
          }
        }
        .roomTime {
          font-size: 12px;
          color: rgb(142, 144, 156);
          margin-top: 10px;
          margin-bottom: 10px;
          > p {
            margin-bottom: 5px;
          }
        }
        .roomButton {
          display: flex;
          justify-content: right;
        }
        .sign-up {
          width: 80px;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          background-color: #ee2546;
          color: #f8fdff;
          border-radius: 4px;
          border: none;
          cursor: pointer;
          margin-right: 10px;
        }
        .goin-home{
          width: 80px;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          background: #158AEA;
          color: #f8fdff;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
        .room-name {
          // max-width: 140px;
          font-size: 14px;
          color: #334350;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          vertical-align: middle;
        }
        .current-room {
          width: 78px;
          line-height: 25px;
          font-size: 14px;
          border: 1px solid #494d73;
          background: none;
          color: #f8fdff;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          label{
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #28ba57;
            margin-right: 3px;
          }
        }
      }
      .room-info {
        overflow: hidden;
        position: relative;
        z-index: 2;
        padding: 0 2px;
        button {
          width: 70px;
          height: 26px;
          line-height: 26px;
          border-radius: 13px;
          background: #d6a41f;
          color: #ffffff;
          float: right;
          cursor: pointer;
        }
        .room-living {
          float: right;
          font-size: 12px;
          color: #ee2546;
          margin-bottom: 5px;
          img {
            vertical-align: middle;
            display: inline-block;
            width: 15px;
            height: auto;
          }
        }
      }
    }
    .noCurrentRoom{
      width: 100%;
      border-bottom: 1px solid #D8D8D8;
      padding: 10px 15px;
    }
    .isCurrentRoom{
      background-color: #363962;
      padding-bottom: 10px;
    }
  }
  .onplay-room-name {
    font-size: 14px;
    color: #3498db;
  }
  .dialog-btn-common {
    border: none;
    outline: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 6px;
    width: 80px;
    height: 34px;
  }
  .dialog-btn-cancel {
    border: 1px solid #dededd;
    color: #464852;
    margin-right: 15px;
  }
  .dialog-btn-sure {
    background: #3498db;
    color: #ffffff;
  }
}
</style>
