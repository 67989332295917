<template>
  <div class="forget-item">
    <div slot="title" class="login-header">找回密码</div>
    <template>
      <el-form
        ref="loginForm2"
        :model="reqData2"
        :rules="loginForm2Rules"
        class="login-form"
        label-position="left"
      >
        <el-form-item v-if="step1" prop="phone" class="login-form-item">
          <el-input
            v-model="reqData2.phone"
            prefix-icon="el-icon-mobile-phone"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item v-if="step1" prop="uuid" class="login-form-item">
          <el-input
            v-model="reqData2.uuid"
            prefix-icon="el-icon-connection"
            placeholder="请输入验证码"
          />
          <el-button type="text" :disabled="smsDisabled" class="btn_sms" @click="_getSms">{{ smsBtnText }}</el-button>
        </el-form-item>
        <el-form-item v-if="step1" label-width="0">
          <div class="login-form-btn" @click.stop="handleNext">下一步</div>
        </el-form-item>
        <el-form-item v-if="step2" prop="password" class="login-form-item">
          <el-input
            v-model="reqData2.password"
            prefix-icon="el-icon-lock"
            show-password
            type="password"
            placeholder="请输入新密码"
          />
        </el-form-item>
        <el-form-item v-if="step2" prop="password2" class="login-form-item">
          <el-input
            v-model="reqData2.password2"
            prefix-icon="el-icon-lock"
            show-password
            type="password"
            placeholder="请确认密码"
          />
        </el-form-item>
        <el-form-item v-if="step2" label-width="0">
          <div class="login-form-btn" @click.stop="handleNext2">下一步</div>
        </el-form-item>
      </el-form>
    </template>
    <template v-if="step3">
      <img src="../assets/images/icon_ok.png" alt="" class="icon_ok">
      <p class="midP">您已成功设置密码，请使用新密码登录</p>
      <div class="login-form-btn" @click.stop="goLogin">马上登录</div>
    </template>
  </div>
</template>
<script>
import { Message } from 'element-ui'
export default {
  data() {
    return {
      activeName: 'first',
      step1: true,
      step2: false,
      step3: false,
      smsTime: 30,
      reqData2: {
        phone: '',
        password: '',
        password2: '',
        uuid: '',
        device: 'pc'
      },
      smsDisabled: false,
      smsBtnText: '获取验证码',
      loginForm2Rules: {
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'number',
            message: '手机号格式不正确',
            trigger: 'blur',
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
              if (!phonereg.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
        uuid: [
          { required: true, message: '验证码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    dialogObj() {
      return this.$store.state.home.dialogObj
    }
  },

  watch: {
    'dialogObj.dialogVisible'(newVal) {
      if (!newVal) {
        // this.$refs['loginForm'].resetFields()
        this.$refs['loginForm2'].resetFields()
      }
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    goLogin() {
      this.$store.commit('home/set_dialogobj', { dialogVisible: true })
      this.$store.commit('home/set_dialogobj3', { dialogVisible: false })
    },
    async _getSms() {
      const params = {
        phone: this.reqData2.phone
      }
      if (params.phone !== '') {
        await this.$store.dispatch('login/handleSms', params)
        setInterval(() => {
          this.smsBtnText = this.smsTime + '秒后重新获取'
          this.smsTime--
          if (this.smsTime < 0) {
            this.smsBtnText = '获取验证码'
            this.smsDisabled = false
            clearInterval()
          }
        }, 1000)
      }
    },
    // 下一步
    handleNext() {
      if (this.reqData2.phone !== '' && this.reqData2.uuid !== '') {
        this.step2 = true
        this.step1 = false
      }
    },
    async handleNext2() {
      this.$refs['loginForm2'].validate(async valid => {
        if (valid) {
          if (this.reqData2.password2 !== this.reqData2.password) {
            Message({
              type: 'error',
              message: '请确认密码是否正确'
            })
          } else {
            const params = this.reqData2
            const res = await this.$store.dispatch('login/handlePassword', params)
            console.log(res)
            if (res.Status === 400) {
              Message({
                type: 'error',
                message: res.message
              })
            } else if (res.Status === 0) {
              Message({
                type: 'success',
                message: res.message
              })
              this.step3 = true
              this.step2 = false
              this.step1 = false
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.loginBox {
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog{
     border-radius: 8px;
     padding-bottom: 20px;
  }
  .el-dialog__body{
    padding: 0;

  }

}
.forget-item {
  .login-header {
    font-size: 22px;
    color: #fff;
    line-height: 80px;
    height: 80px;
    background: #2c2c2c;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-left: 50px;
  }
  .el-tabs__item.is-active{
    color:#111111;
  }
  .el-tabs__active-bar{
    background-color: #111111;
  }
  .el-tabs__item{
    color:#656565;
  }
  .el-button--text{
      color:#111111;
    }
  .el-tabs__nav-scroll{
    padding-left: 50px;
  }
  .login-form {
    margin-top: 30px;
    .login-form-item {
      width: 380px;
      margin: 25px auto;
      padding: 0 15px;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      border: 1px solid #c0c4cc;
      .el-form-item__label {
        text-align: center !important;
        color: #394d62;
        font-size: 16px;
      }
      input {
        flex: 1;
        font-size: 14px;
        color: #394d62;
        outline: none;
        border: none;
      }
    }
    .btn_sms{
      position: absolute;
      right: 0;
    }
    .login-form-btn {
      width: 82%;
      margin: 0  auto;
      // background: linear-gradient(90deg, #ed000a, #fe6c39);
      background-color: #ee2547;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      border-radius: 8px;
      cursor: pointer;
      height: 50px;
    }
  }
  .icon_ok{
    display: block;
    margin: 0 auto;

  }
  .midP{
    text-align: center;
    font-size: 18px;
    color: #0a0a0a;
  }
  .login-form-btn {
    width: 82%;
    margin: 0  auto;
    // background: linear-gradient(90deg, #ed000a, #fe6c39);
    background-color: #ee2547;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
    height: 50px;
  }
}
</style>
