<template>
  <div class="private-talk">
    <div class="scroll-item private-left" v-if="userInfo.utype !== 0">
      <template v-if="privateUserList.length">
        <div class="allTalk">
          <img src="../assets/images/more_menu.png" alt="" />
          <span>全部会话</span>
        </div>
        <div
          v-for="item in privateUserList"
          :key="item.id"
          class="user-item"
          @click.stop="handleChangePrivateUser(item)"
        >
          <img
            class="initImg"
            src="../assets/images/craig/avatar/1.png"
            alt=""
          />
          <div v-if="item.nick.indexOf('游客') !== -1" class="user-name">
            {{ item.nick }}
          </div>
          <div v-else class="user-name">
            {{ item.mtaccr }}
          </div>
          <div
            class="close-user"
            @click.stop.stop="handleRemoveUser(item, $event)"
          />
          <div v-if="item.isred" class="user-item-red" />
        </div>
      </template>
      <div v-else style="color: #ffffff">暂无私聊用户</div>
    </div>
    <div class="private-right">
      <div class="user-info">
        <span v-if="userInfo.utype == 0" class="user-info-name">{{
          distributecustomer.nick
        }}</span>
        <span
          v-if="
            privateUserInfo.nick
              ? privateUserInfo.nick.indexOf('游客') !== -1
              : true
          "
          class="user-info-name"
          >{{ privateUserInfo.nick }}</span
        >
        <span v-else class="user-info-name">{{ privateUserInfo.mtaccr }}</span>
        <span class="user-info-close" @click.stop="handleHide" />
      </div>
      <div ref="private-chat-con" class="scroll-item private-chat-con">
        <template v-if="privateChatHistoryList.length">
          <div v-for="item in privateChatHistoryList" :key="item.id">
            <!-- 用户消息展示 -->
            <div
              v-if="item.sid === privateUserInfo.id"
              class="private-chat-item private-chat-item-left"
            >
              <div class="private-chat-item-user">
                <span v-if="privateUserInfo.mtaccr" class="item-user-name">{{
                  privateUserInfo.mtaccr
                }}</span>
                <span v-else class="item-user-name">{{
                  privateUserInfo.nick
                }}</span>
                <span class="item-user-time">{{ handleTime(item.ut) }}</span>
              </div>
              <div class="private-chat-content commonUserLeft">
                <img class="customerAvatar" :src="item.headerimg" alt="" />
                <span v-if="item.param.TXT" v-html="getHref(item.param.TXT)"></span>
                <span v-if="item.param.IMG">
                  <img :src="item.param.IMG" style="max-width:200px;"/>
                </span>
              </div>
            </div>
            <!-- 新增客服消息展示 (当只有是普通用户的时候才展示) -->
            <div
              v-if="userInfo.utype == 0 && item.sid === distributecustomer.id"
              class="private-chat-item private-chat-item-left"
            >
              <div class="private-chat-item-user">
                <span class="item-user-name">{{ distributecustomer.nick }}</span
                ><span class="item-user-time">{{ handleTime(item.ut) }}</span>
              </div>
              <div class="private-chat-content craigRight">
                <img
                  class="customerAvatar"
                  :src="distributecustomer.avatar"
                  alt=""
                />
                <span v-if="item.param.TXT" v-html="getHref(item.param.TXT)"></span>
                <span v-if="item.param.IMG">
                  <img :src="item.param.IMG" style="max-width:200px;"/>
                </span>
              </div>
            </div>
            <!-- 自己消息展示 -->
            <div
              v-if="item.sid === userInfo.id"
              class="private-chat-item private-chat-item-right"
            >
              <div class="private-chat-item-user">
                <span class="item-user-time">{{ handleTime(item.ut) }}</span>
                <!-- <span class="item-user-name">{{ userInfo.nick || userInfo.whoami }}</span> -->
                <span class="item-user-name">{{ item.sname }}</span>
              </div>
              <div class="private-chat-content craigLeft">
                <span v-if="item.param.TXT" v-html="getHref(item.param.TXT)"></span>
                <span v-if="item.param.IMG">
                  <img :src="item.param.IMG" style="max-width:200px;" @click="lookBigImg(item.param.IMG)"/>
                </span>
                <!-- 普通用户头像 -->
                <img
                  v-if="userInfo.utype == 0"
                  class="customerAvatar"
                  :src="item.headerimg"
                  alt=""
                />
                <!-- 讲师头像 -->
                <img
                  v-if="userInfo.utype == 1"
                  class="customerAvatar"
                  :src="userInfo.avatar"
                  alt=""
                />
                <!-- 客服头像 -->
                <img
                  v-if="userInfo.utype == 2"
                  class="customerAvatar"
                  :src="userInfo.avatar"
                  alt=""
                />
              </div>
            </div>
          </div>
        </template>
        <div v-else class="no-data">暂无聊天记录</div>
      </div>
      <div class="private-chat-send">
        <!-- 聊天功能区域 -->
        <div class="send_menu">
          <el-upload
            class="avatar-uploader"
            action="https://www.rlcproltd.net/apis/api/users/upload"
            :show-file-list="false"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-picture icon_size"></i>
          </el-upload>
        </div>
        <!-- 输入区域 -->
        <div class="send-input">
          <textarea
            v-model="sendTxt"
            @keyup.enter="handlePrivateMessageSend"
            @change="handleInputChange"
          />
        </div>
        <!-- 发送按钮 -->
        <div class="send-btn">
          <span>按Enter键 发送消息</span>
          <button @click.stop="handlePrivateMessageSend">发送</button>
        </div>
      </div>
    </div>
    <!-- 聊天区域大图预览 -->
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <div style="text-align:center;">
        <img :src="thisImg" style="width:800px;"></img>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      sendTxt: "",
      imgSrc: require("../assets/images/craig/headerImg.png"),
      // 绑定在upload组件的对象
      headers: { Authorization: "" },
      dialogVisible: false,
      thisImg: ''
    };
  },
  computed: {
    privateUserList() {
      return this.$store.state.home.privateUserList;
    },
    privateUserInfo() {
      return this.$store.state.home.privateUserInfo;
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    },
    roomInfo() {
      return this.$store.state.home.roomInfo;
    },
    privateChatHistoryList() {
      return this.$store.state.home.privateChatHistoryList;
    },
    socket() {
      return this.$store.state.home.socket;
    },
    // 分配的客服信息
    distributecustomer() {
      return this.$store.state.home.distributecustomer;
    },
  },
  watch: {
    "$store.state.home.privateChatHistoryList"() {
      const self = this;
      this.$refs["private-chat-con"].scrollTop =
        this.$refs["private-chat-con"].scrollHeight;
      setTimeout(() => {
        self.$refs["private-chat-con"].scrollTop =
          self.$refs["private-chat-con"].scrollHeight;
      }, 500);
    },
  },
  mounted() {
    this.headers.Authorization = JSON.parse(sessionStorage.getItem('token')).token;
    const self = this;
    this.$refs["private-chat-con"].scrollTop =
      this.$refs["private-chat-con"].scrollHeight;
    setTimeout(() => {
      self.$refs["private-chat-con"].scrollTop =
        self.$refs["private-chat-con"].scrollHeight;
    }, 500);
  },
  methods: {
    lookBigImg(img) {
      this.thisImg = img
      this.dialogVisible = true
    },
    handleInputChange(event) {},
    // 最小化私聊窗口
    handleHide() {
      this.$store.commit("home/set_dialogobj", {
        dialogVisible: false,
        isCloseIcon: true,
      });
    },
    // 私聊消息发送
    handlePrivateMessageSend() {
      if (this.sendTxt.replace(/^\s*/, "") || this.imageUpload) {
        //utype --> 0:普通用户 1:讲师 2:客服
        var headerimg = null;
        if (this.userInfo.utype == 0 || this.userInfo.utype == undefined) {
          if (sessionStorage.getItem("headerImg")) {
            headerimg = sessionStorage.getItem("headerImg");
          } else {
            headerimg = this.imgSrc;
          }
        } else {
          headerimg = this.userInfo.avatar || this.distributecustomer.avatar;
        }
        // const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        const nick = String(this.userInfo.mtaccr);
        let option = {};
        console.log(nick);
        if (this.userInfo.utype == 0 || this.userInfo.utype == undefined) {
          // 如果是普通用户登录，则传分配的客服信息
          option = {
            broomid: this.roomInfo.id,
            sname: nick !== "undefined" ? nick : this.userInfo.nick,
            sid: this.userInfo.id,
            rid: this.distributecustomer.id,
            rname: this.distributecustomer.nick,
            type: 11,
            headerimg,
          };
        } else {
          // 如果是客服或老师登录，则传私聊对象（当前正在聊天中的用户）的信息
          option = {
            broomid: this.roomInfo.id,
            sname: this.userInfo.whoami,
            sid: this.userInfo.id,
            rid: this.privateUserInfo.id,
            rname: this.privateUserInfo.nick,
            type: 11,
            param: {
              TXT: this.sendTxt,
            },
            headerimg,
          };
        }
        if (this.imageUpload) {
          option.param = {
            IMG: this.imageUpload,
          };
        } else {
          option.param = {
            TXT: this.sendTxt,
          };
        }
        this.socket.send(JSON.stringify(option));
        this.sendTxt = "";
      } else {
        this.$message({
          type: "error",
          message: "请输入内容后在发送",
        });
      }
    },
    // 移除私聊用户
    async handleRemoveUser(info) {
      const list = Object.assign([], this.privateUserList);
      list.forEach((item, index) => {
        if (item.id === info.id) {
          list.splice(index, 1);
        }
      });
      // 判断如果移除后用户列表为空提示客服或者老师去聊天室找私聊对象
      if (list.length) {
        this.$store.commit("home/set_privateUserInfo", list[0]);
        this.$store.commit("home/set_privateUserList", list);
        const params = {
          broomid: this.roomInfo.id,
          type: 11,
          id1: this.userInfo.id,
          id2: list[0].id,
          page: 0,
          size: 50,
        };
        await this.$store.dispatch(
          "home/getUserPrivateChatHistoryListById",
          params
        );
      } else {
        this.$message({
          type: "error",
          message:
            "您的私聊列表已经没有用户，请您到所在房间聊天记录中选择需要私聊的对象",
        });
        this.$store.commit("home/set_dialogobj", {
          dialogVisible: false,
          isCloseIcon: true,
        });
      }
    },
    // 切换私聊用户
    async handleChangePrivateUser(info) {
      info.isred = false;
      this.$store.commit("home/set_privateUserInfo", info);
      const params = {
        broomid: this.roomInfo.id,
        type: 11,
        id1: this.userInfo.id,
        id2: info.id,
        page: 0,
        size: 50,
      };
      await this.$store.dispatch(
        "home/getUserPrivateChatHistoryListById",
        params
      );
      // 切换用户后需要把用户有新消息的提示字段改成false
      const list = [...this.privateUserList];
      list.forEach((item) => {
        if (item.id === info.id) {
          item.isred = false;
        }
      });
      this.$store.commit("home/set_privateUserList", list);
    },
    // 时间处理
    handleTime(time) {
      if (new Date(Number(time)).toDateString() === new Date().toDateString()) {
        return moment(time).format("HH:mm:ss");
      } else if (new Date(Number(time)) < new Date()) {
        return moment(time).format("MM-DD HH:mm:ss");
      }
    },
    // 提取字符串中的链接，并替换a标签
    getHref(content) {
      if (!content) {
        return "";
      }
      const urlPattern =
        /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[()~#&\-=?+%/.\w]+)?/g;
      content = content.replace(urlPattern, (match) => {
        const urlHttp = match.indexOf("http://") && match.indexOf("https://");
        const url = urlHttp === -1 ? match.split("/")[0] : match.split("/")[2];
        const href = urlHttp === -1 ? `https://${match}` : match;
        return `<a target="_blank" href="${href}" style="color: #3298FF">${href}</a>`;
      });
      return content;
    },
    // 上传头像成功
    handleAvatarSuccess(res, file) {
      if (file.response.Status == 0) {
        this.imageUpload = file.response.url;
        this.handlePrivateMessageSend()
      } else {
        this.$message.error("图片上传失败!");
      }
    },
    // 上传头像之前
    beforeAvatarUpload(file) {
      const isJPG = file.type == "image/jpeg" || "image/png" || "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("请上传正确图片格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="less" scoped>
.icon_size {
  font-size: 30px;
  cursor: pointer;
  margin: 10px 0 0 10px;
}
.private-talk {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  .private-left {
    width: 200px;
    height: 568px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    border-right: 1px solid #ececec;
    .allTalk {
      height: 38px;
      line-height: 38px;
      color: #000;
      border-bottom: 1px solid #ededed;
      box-sizing: border-box;
      padding-left: 8px;
      img {
        width: 14px;
        margin-right: 5px;
      }
    }
    .user-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      background: #f5f5f5;
      padding: 6px 8px;
      margin-bottom: 5px;
      .initImg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }

      .user-item-red {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
        margin-left: 5px;
      }
      .user-name {
        flex: 1;
        // max-width: 105px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #000;
      }
      .close-user {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: url("../assets/images/teacherJs/cancel.png") no-repeat
          center;
        background-size: 15px 15px;
        cursor: pointer;
        display: none;
      }
    }
    .user-item:hover .close-user {
      display: block;
    }
  }
  .private-right {
    flex: 1;
    height: 598px;
    background: #ffffff;
    .user-info {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      line-height: 38px;
      // border-bottom: 1px solid #dedede;
      padding: 0 10px;
      .user-info-name {
        font-size: 14px;
        color: #000;
      }
      .user-info-close {
        width: 38px;
        height: 38px;
        background: url("../assets/images/user-info-close.png") no-repeat center;
        // background-size: 12px 1px;
        background-size: 12px 12px;
        cursor: pointer;
      }
    }
    .private-chat-con::-webkit-scrollbar {
      width: 3px;
    }
    .private-chat-con::-webkit-scrollbar-thumb {
      background-color: #ee2547;
      border-radius: 2em;
    }
    .private-chat-con::-webkit-scrollbar-track {
      background-color: #b6bfc2;
      border-radius: 2em;
    }
    .private-chat-con {
      height: 340px;
      padding: 20px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #f5f5f5;
      .private-chat-item {
        margin-bottom: 20px;
        .private-chat-item-user {
          font-size: 12px;
          .item-user-name {
            color: #929292;
            margin-right: 15px;
          }
          .item-user-time {
            color: #b3b3b3;
          }
        }
        .private-chat-content {
          text-align: left;
          font-size: 13px;
          color: #000;
          padding: 0 10px;
          max-width: 530px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          .customerAvatar {
            width: 44px;
            height: 44px;
            border-radius: 50%;
          }
          span {
            padding: 8px;
            background-color: #ffffff;
            border-radius: 7px;
          }
        }
        .craigRight {
          padding: 0 10px 0 0;
          justify-content: flex-start;
          img {
            margin-right: 10px;
          }
        }
        .craigLeft {
          padding: 0 0 0 10px;
          img {
            margin-left: 10px;
          }
        }
        .commonUserLeft {
          padding: 0 10px 0 0;
          justify-content: flex-start;
          img {
            margin-right: 10px;
          }
        }
      }
      .private-chat-item-right {
        overflow: hidden;
        .private-chat-item-user {
          text-align: right;
          .item-user-name {
            margin-left: 15px;
          }
        }
        .private-chat-content {
          float: right;
        }
      }
      .no-data {
        font-size: 12px;
        text-align: center;
        color: #9aabb8;
      }
    }
    .private-chat-send {
      height: 150px;
      // border-top: 1px solid #dedede;
      position: relative;
      .send-input {
        height: 100px;
        textarea {
          padding: 5px 10px;
          width: 100%;
          height: 100%;
          resize: none;
          box-sizing: border-box;
          border: none;
          outline: none;
        }
      }
      .send-btn {
        text-align: right;
        padding: 0 10px;
        button {
          width: 80px;
          height: 30px;
          color: #ffffff;
          font-size: 12px;
          background: #ee2547;
          border: none;
          border-radius: 5px;
        }
        span {
          color: #c6c6c6;
          font-size: 12px;
          margin-right: 10px;
          transform: scale(0.8);
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .private-talk {
    .private-left {
      width: 80px;
      height: 400px;
      padding: 5px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 5px;
      .user-item {
        padding: 10px 3px;
        margin-bottom: 5px;
      }
    }
    .private-right {
      height: 410px;
      border-radius: 5px;
      .private-chat-con {
        box-sizing: border-box;
        height: 280px;
      }
      .private-chat-send {
        height: 90px;
        .send-input {
          height: 50px;
        }
        .send-btn {
          button {
            width: 60px;
            height: 24px;
          }
        }
      }
    }
  }
}
</style>
