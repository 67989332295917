<template>
  <div class="box" v-if="control">
    <img class="advertImg" :src="imgurl" alt="">
  </div>
</template>

<script>
import { zhiboBanner } from '../../../../../api/info';
export default {
  name:'advertRight',
  data(){
    return{
      imgurl:'',
      control:false,
    }
  },
  created(){
    this.init();
  },
  methods:{
    // 获取swiper
    init(){
      const option = {
        languageidentification:1,
        type:38,
        region:1
      }
      zhiboBanner(option).then(res=>{
        if(res.Status === 0){
          this.imgurl = res.Data[0].imgUrl;
          this.control = true;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 298px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 20px #c3c6c8;
    background: #ffffff;
    border-radius: 5px;
    .advertImg{
      width: 298px;
      height: 30px;
    }
  }
</style>